<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  data() {
    return {

    }
  },
  created() {
    // window.test = (info) => {
    //   console.log(info)
    //   this.$remote = info
    // };
  }
}

</script>
<style></style>
