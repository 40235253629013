<template>
    <div>
        <div class="small-title">
            服务类型比例
        </div>
        <div class="service-type">
            <div class="service-type-item">
                <div class="service-type-item-canvas nianshen-canvas" id="nian-shen">
                </div>
                <div>
                    <div class="service-type-item-line"></div>
                    <div class="service-type-item-title">年审数据</div>
                    <div class="service-type-item-number">
                        <div class="number-bai">{{ nianshenbaifenbi }}%</div>
                        <div class="number-all">{{ nianshen }}</div>
                    </div>
                </div>
            </div>
            <div class="service-type-item marginTop">
                <div class="service-type-item-canvas zhuanxiang-canvas" id="zhuan-xiang">
                </div>
                <div>
                    <div class="service-type-item-line"></div>
                    <div class="service-type-item-title">专项数据</div>
                    <div class="service-type-item-number">
                        <div class="number-bai" style="color: #65A4FF;">{{ zhuanxiangbaifenbi }}%</div>
                        <div class="number-all">{{ zhuanxiang }}</div>
                    </div>
                </div>
            </div>
            <div class="service-type-item marginTop">
                <div class="service-type-item-canvas fuzhu-canvas" id="fu-zhu-zhang">
                </div>
                <div>
                    <div class="service-type-item-line"></div>
                    <div class="service-type-item-title">辅助账数据</div>
                    <div class="service-type-item-number">
                        <div class="number-bai" style="color: #03E5F1;">{{ fuzhuzhangbaifenbi }}%</div>
                        <div class="number-all">{{ fuzhuzhang }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="small-title">
            一审、二审信息统计
        </div>
        <div class="audit-info">
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ oneInfoData.yishendailingqu }}</div>
                <div class="audit-info-item-text">一审待领取</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ twoInfoData.ershenlingquzhuangtai }}</div>
                <div class="audit-info-item-text">二审待领取</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ oneInfoData.yishenwentishu }}</div>
                <div class="audit-info-item-text">一审问题数</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ twoInfoData.ershenliushuihaobianhao }}</div>
                <div class="audit-info-item-text">二审问题数</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ oneInfoData.yishenwentijiejueshu }}</div>
                <div class="audit-info-item-text">一审问题解决数</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ twoInfoData.ershenwentijiejueshu }}</div>
                <div class="audit-info-item-text">二审问题解决数</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "shenJiRight",
    data() {
        return {
            zhuanxiangbaifenbi: 0,
            nianshenbaifenbi: 0,
            fuzhuzhangbaifenbi: 0,
            oneInfoData: {
                yishendailingqu: 0,
                yishenwentijiejueshu: 0,
                yishenwentishu: 0,
                yishenxinxiweijiejue: 0,
                yishenxinxiweijiejuebaifenbi: 0,
                yishenxinxiyijiejue: 0,
                yishenxinxiyijiejuebaifenbi: 0
            },
            twoInfoData: {
                ershenlingquzhuangtai: 0,
                ershenliushuihaobianhao: 0,
                ershenwentijiejueshu: 0,
                ershenxinxiweijiejue: 0,
                ershenxinxiweijiejuebaifenbi: 0,
                ershenxinxiyijiejue: 0,
                ershenxinxiyijiejuebaifenbi: 0,
            },
            nianshen:0,
            zhuanxiang:0,
            fuzhuzhang:0
        }
    },
    mounted() {
        this.getFuwuType()
        this.getInfoData()
    },
    methods: {
        setRightData(type, item) {
            if (type == 7) {
                this.fuzhuzhang = item.fuzhuzhang
                this.nianshen = item.nianshen
                this.zhuanxiang = item.zhuanxiang
                this.fuzhuzhangbaifenbi = Number(item.fuzhuzhangbaifenbi)
                this.nianshenbaifenbi = Number(item.nianshenbaifenbi)
                this.zhuanxiangbaifenbi = Number(item.zhuanxiangbaifenbi)
                this.init()
            }
            if (type == 16) this.oneInfoData.yishendailingqu = item.yishendailingqu
            if (type == 17) this.oneInfoData.yishenwentishu = item.yishenwentishu
            if (type == 18) this.oneInfoData.yishenwentijiejueshu = item.yishenwentijiejueshu
            if (type == 19) {
                this.oneInfoData.yishenxinxiweijiejuebaifenbi = Number(item.yishenxinxiweijiejuebaifenbi)
                this.oneInfoData.yishenxinxiyijiejuebaifenbi = Number(item.yishenxinxiyijiejuebaifenbi)
            }
            if (type == 20) this.twoInfoData.ershenlingquzhuangtai = item.ershenlingquzhuangtai
            if (type == 21) this.twoInfoData.ershenliushuihaobianhao = item.ershenliushuihaobianhao
            if (type == 22) this.twoInfoData.ershenxinxiyijiejue = item.ershenxinxiyijiejue
            if (type == 23) {
                this.twoInfoData.ershenxinxiyijiejuebaifenbi = item.ershenxinxiyijiejuebaifenbi
                this.twoInfoData.ershenxinxiweijiejuebaifenbi = item.ershenxinxiweijiejuebaifenbi
            }
        },
        getInfoData() {
            this.$axios.get(
                '/kejifuwushenji/part5'
            ).then(res => {
                this.oneInfoData.yishendailingqu = res.data.yishendailingqu
                this.oneInfoData.yishenwentijiejueshu = res.data.yishenwentijiejueshu
                this.oneInfoData.yishenwentishu = res.data.yishenwentishu

                this.oneInfoData.yishenxinxiweijiejue = res.data.yishenxinxiweijiejue

                this.oneInfoData.yishenxinxiweijiejuebaifenbi = Number(res.data.yishenxinxiweijiejuebaifenbi)

                this.oneInfoData.yishenxinxiyijiejue = res.data.yishenxinxiyijiejue

                this.oneInfoData.yishenxinxiyijiejuebaifenbi = Number(res.data.yishenxinxiyijiejuebaifenbi)
            })
            this.$axios.get(
                '/kejifuwushenji/part6'
            ).then(res => {
                this.twoInfoData.ershenlingquzhuangtai = res.data.ershenlingquzhuangtai
                this.twoInfoData.ershenliushuihaobianhao = res.data.ershenliushuihaobianhao
                this.twoInfoData.ershenwentijiejueshu = res.data.ershenwentijiejueshu

                this.twoInfoData.ershenxinxiweijiejue = res.data.ershenxinxiweijiejue

                this.twoInfoData.ershenxinxiweijiejuebaifenbi = Number(res.data.ershenxinxiweijiejuebaifenbi)

                this.twoInfoData.ershenxinxiyijiejue = res.data.ershenxinxiyijiejue

                this.twoInfoData.ershenxinxiyijiejuebaifenbi = Number(res.data.ershenxinxiyijiejuebaifenbi)
            })
        },
        getFuwuType() {
            this.$axios.get(
                '/kejifuwushenji/part2'
            ).then(res => {
                this.nianshen = res.data.nianshen
                this.zhuanxiang = res.data.zhuanxiang
                this.fuzhuzhang = res.data.fuzhuzhang
                this.zhuanxiangbaifenbi = res.data.zhuanxiangbaifenbi
                this.nianshenbaifenbi = res.data.nianshenbaifenbi
                this.fuzhuzhangbaifenbi = res.data.fuzhuzhangbaifenbi
                this.init()
            })
        },
        init() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var myChart = this.$echarts.init(document.getElementById('nian-shen'));
            // 绘制图表
            myChart.setOption({
                title: {
                    show: false,
                    text: '年审',
                    left: 'center',
                    bottom: '0',
                    textStyle: {
                        color: "#fff",
                        fontWeight: 400,
                        fontSize: titleSize
                    }
                },
                series: [//系列
                    {
                        name: '年审',
                        type: 'pie',//pie类型的图实现环形图
                        radius: ['52%', '60%'],//数组的话，表示内圆和外圆的半径大小，相对于宽高中较小的那一个。
                        center: ['50%', '50%'],//圆心坐标
                        avoidLabelOverlap: false,//是否启用防止标签重叠策略
                        label: {//每个数据的标签
                            show: false,//设置为true则显示第一个数据
                            position: 'center',//位置居中
                        },
                        color: ['#FBDB00', '#004880'],//系列的颜色
                        emphasis: {//高亮，即鼠标经过时的样式
                            scale: false//表示不放大item
                        },
                        labelLine: {
                            show: true
                        },
                        data: [
                            {
                                value: this.nianshenbaifenbi,
                                selected: false,
                                itemStyle: {
                                    normal: {
                                        borderRadius: 8,
                                        color: {
                                            type: 'linear',
                                            colorStops: [
                                                { offset: 0, color: '#22CD92' },
                                                { offset: 1, color: '#10EBDD' },
                                            ],
                                        },
                                    }
                                },
                                label: {
                                    borderRadius: 20,
                                }
                            },
                            {
                                value: 100 - this.nianshenbaifenbi,  //数据 按比例显示的
                                name: "",
                                itemStyle: {
                                    color: "transparent",
                                },
                            },
                        ]
                    }
                ]
            });
            var zhuanxiang = this.$echarts.init(document.getElementById('zhuan-xiang'));
            // 绘制图表
            zhuanxiang.setOption({
                title: {
                    show: false,
                    text: '年审',
                    left: 'center',
                    bottom: '0',
                    textStyle: {
                        color: "#fff",
                        fontWeight: 400,
                        fontSize: titleSize
                    }
                },
                series: [//系列
                    {
                        name: '年审',
                        type: 'pie',//pie类型的图实现环形图
                        radius: ['52%', '60%'],//数组的话，表示内圆和外圆的半径大小，相对于宽高中较小的那一个。
                        center: ['50%', '50%'],//圆心坐标
                        avoidLabelOverlap: false,//是否启用防止标签重叠策略
                        label: {//每个数据的标签
                            show: false,//设置为true则显示第一个数据
                            position: 'center',//位置居中
                        },
                        color: ['#FBDB00', '#004880'],//系列的颜色
                        emphasis: {//高亮，即鼠标经过时的样式
                            scale: false//表示不放大item
                        },
                        labelLine: {
                            show: true
                        },
                        data: [
                            {
                                value: this.zhuanxiangbaifenbi,
                                selected: false,
                                itemStyle: {
                                    normal: {
                                        borderRadius: 8,
                                        color: {
                                            type: 'linear',
                                            colorStops: [
                                                { offset: 0, color: '#3A88FC' },
                                                { offset: 1, color: '#0BDCFF' },
                                            ],
                                        },
                                    }
                                },
                                label: {
                                    borderRadius: 20,
                                }
                            },
                            {
                                value: 100 - this.zhuanxiangbaifenbi,  //数据 按比例显示的
                                name: "",
                                itemStyle: {
                                    color: "transparent",
                                },
                            },
                        ]
                    }
                ]
            });
            var fuzhu = this.$echarts.init(document.getElementById('fu-zhu-zhang'));
            // 绘制图表
            fuzhu.setOption({
                title: {
                    show: false,
                    text: '年审',
                    left: 'center',
                    bottom: '0',
                    textStyle: {
                        color: "#fff",
                        fontWeight: 400,
                        fontSize: titleSize
                    }
                },
                series: [//系列
                    {
                        name: '年审',
                        type: 'pie',//pie类型的图实现环形图
                        radius: ['52%', '60%'],//数组的话，表示内圆和外圆的半径大小，相对于宽高中较小的那一个。
                        center: ['50%', '50%'],//圆心坐标
                        avoidLabelOverlap: false,//是否启用防止标签重叠策略
                        label: {//每个数据的标签
                            show: false,//设置为true则显示第一个数据
                            position: 'center',//位置居中
                        },
                        color: ['#FBDB00', '#004880'],//系列的颜色
                        emphasis: {//高亮，即鼠标经过时的样式
                            scale: false//表示不放大item
                        },
                        labelLine: {
                            show: true
                        },
                        data: [
                            {
                                value: this.fuzhuzhangbaifenbi,
                                selected: false,
                                itemStyle: {
                                    normal: {
                                        borderRadius: 8,
                                        color: {
                                            type: 'linear',
                                            colorStops: [
                                                { offset: 0, color: '#06E4F6' },
                                                { offset: 1, color: '#0AEFDA' },
                                            ],
                                        },
                                    }
                                },
                                label: {
                                    borderRadius: 20,
                                }
                            },
                            {
                                value: 100 - this.fuzhuzhangbaifenbi,  //数据 按比例显示的
                                name: "",
                                itemStyle: {
                                    color: "transparent",
                                },
                            },
                        ]
                    }
                ]
            });
        }
    }
}
</script>

<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.service-type {
    padding: 1rem 3.5rem 0.5625rem 1.875rem;

    .service-type-item {
        display: flex;
        align-items: flex-start;

        .service-type-item-canvas {
            width: 5.875rem;
            height: 5.875rem;
        }

        .nianshen-canvas {
            background-image: url(../../assets/shenji/nianshen.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .zhuanxiang-canvas {
            background-image: url(../../assets/shenji/zhuanxiang.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .fuzhu-canvas {
            background-image: url(../../assets/shenji/fuzhu.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .service-type-item-line {
            height: 0.125rem;
            width: 17.375rem;
            background-color: #F5F9FF;
            opacity: 0.5;
            position: relative;
        }

        .service-type-item-line::after {
            content: "";
            height: 0.125rem;
            width: 1.2rem;
            background-color: #F5F9FF;
            transform: rotate(-45deg);
            position: absolute;
            top: 0.4rem;
            left: -0.99rem;
        }

        .service-type-item-title {
            margin-left: 1.8125rem;
            margin-top: 1rem;
            color: #BADDF1;
            font-size: 1rem;
        }

        .service-type-item-number {
            margin-left: 1.8125rem;
            margin-top: 0.2rem;
            display: flex;
            align-items: center;
            font-size: 1.25rem;
            font-family: 'RobotoMedium';
            color: $fontWhiteColor;
            .number-bai {
                color: #22CD92;
            }
            .number-all {
                margin-left: 1.5625rem;
            }
        }
    }

    .marginTop {
        margin-top: 1.1875rem;
    }
}

.audit-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 26.31vw;

    .audit-info-item {
        width: 11.25rem;
        height: 8.0625rem;
        background-image: url(../../assets/shenji/yishen.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;

        .audit-info-item-number {
            padding-top: 0.75rem;
            font-weight: 500;
            font-size: 1.25rem;
            color: #03E5F1;
            font-family: 'RobotoMedium';
        }

        .audit-info-item-text {
            margin-top: 1rem;
            font-weight: 500;
            font-size: 1rem;
            color: #E5F1FB;
        }
    }
}
</style>