<template>
    <div>
        <div class="small-title">
            订单信息预览
        </div>
        <div class="work-copyright-canvas" id="order-info">
        </div>
        <div class="audit-info">
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ orderInfo.dingdanleijijine }}</div>
                <div class="audit-info-item-text">订单累计金额</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ orderInfo.dingdanshijileijijine }}</div>
                <div class="audit-info-item-text">订单实际累计金额</div>
            </div>
        </div>
        <div class="small-title">
            合同信息预览
        </div>
        <div class="work-copyright-canvas" id="agreement-info">
        </div>
        <div class="audit-info">
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ agreementInfo.hetongleijijine }}</div>
                <div class="audit-info-item-text">合同累计金额</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ agreementInfo.hetongshulianghuizong }}</div>
                <div class="audit-info-item-text">合同数量汇总</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'customerRight',
    data() {
        return {
            orderInfo:{},
            agreementInfo:{}
        }
    },
    mounted() {
        this.getOrderInfo()
    },
    methods: {
        setRightData(type,item){
            if(type == 37){
                this.orderInfo.weitongguobaifenbi = item.weitongguobaifenbi
                this.orderInfo.shenhezhongbaifenbi = item.shenhezhongbaifenbi
                this.orderInfo.yichengjiaobaifenbi = item.yichengjiaobaifenbi
                this.orderInfo.dingdanleijijine = item.dingdanleijijine
                this.orderInfo.dingdanshijileijijine = item.dingdanshijileijijine
                this.orderInit()
            }
            if(type == 38){
                this.agreementInfo.weitongguobaifenbi = item.weitongguobaifenbi
                this.agreementInfo.yitongguobaifenbi = item.yitongguobaifenbi
                this.agreementInfo.shenhezhongbaifenbi = item.shenhezhongbaifenbi
                this.agreementInfo.hetongleijijine = item.hetongleijijine
                this.agreementInfo.hetongshulianghuizong = item.hetongshulianghuizong
                this.agreementInit()
            }
        },
        getOrderInfo() {
            this.$axios.get(
                '/kehuyingxiaoguanli/part4'
            ).then(res => {
                this.orderInfo = res.data
                this.orderInit()
            })
            this.$axios.get(
                '/kehuyingxiaoguanli/part5'
            ).then(res => {
                this.agreementInfo = res.data
                this.agreementInit()
            })
        },
        agreementInit() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var varEchart = this.$echarts.init(document.getElementById('agreement-info'));
            varEchart.setOption({
                series: [
                    {
                        avoidLabelOverlap: false,
                        type: 'pie',
                        roseType: 'area', // 玫瑰图
                        center: ['45%', '50%'],
                        radius: ['20%', '50%'],
                        label: {
                            normal: {
                                formatter: '{c}%\n————\n{b}',
                                textStyle: {
                                    fontSize: titleSize * 0.83
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                length: 10,
                                length2: 30
                            }
                        },
                        data: [{
                            name: '审核中',
                            value: Number(this.agreementInfo.shenhezhongbaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#62f4a8',

                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#62f4a8'
                                    }, {
                                        offset: 1,
                                        color: '#31e3ff'
                                    }])
                                }
                            }
                        }, {
                            name: '已成交',
                            value: Number(this.agreementInfo.yitongguobaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#3a88fc'
                                    }])
                                }
                            }
                        }, {
                            name: '未通过',
                            value: Number(this.agreementInfo.weitongguobaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#69bbff'
                                    }])
                                }
                            }
                        }]
                    }
                ]
            })
        },
        orderInit() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var varEchart = this.$echarts.init(document.getElementById('order-info'));
            varEchart.setOption({
                series: [
                    {
                        avoidLabelOverlap: false,
                        type: 'pie',
                        roseType: 'area', // 玫瑰图
                        center: ['45%', '50%'],
                        radius: ['20%', '50%'],
                        label: {
                            normal: {
                                formatter: '{c}%\n————\n{b}',
                                textStyle: {
                                    fontSize: titleSize * 0.83
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                length: 10,
                                length2: 30
                            }
                        },
                        data: [{
                            name: '审核中',
                            value: Number(this.orderInfo.shenhezhongbaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#62f4a8',

                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#62f4a8'
                                    }, {
                                        offset: 1,
                                        color: '#31e3ff'
                                    }])
                                }
                            }
                        }, {
                            name: '已成交',
                            value: Number(this.orderInfo.yichengjiaobaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#3a88fc'
                                    }])
                                }
                            }
                        }, {
                            name: '未通过',
                            value: Number(this.orderInfo.weitongguobaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#69bbff'
                                    }])
                                }
                            }
                        }]
                    }
                ]
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.work-copyright-canvas {
    width: 26.31vw;
    height: 14.75rem;
    position: relative;
    background-image: url(../../assets/highTech/leftBg.png);
    background-size: 30% 60%;
    background-repeat: no-repeat;
    background-position: 42% 48%;
}

.audit-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 26.31vw;

    .audit-info-item {
        width: 11.25rem;
        height: 8.0625rem;
        background-image: url(../../assets/shenji/yishen.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;

        .audit-info-item-number {
            padding-top: 0.75rem;
            font-weight: 500;
            font-size: 1.25rem;
            color: #03E5F1;
            font-family: 'RobotoMedium';
        }

        .audit-info-item-text {
            margin-top: 1rem;
            font-weight: 500;
            font-size: 1rem;
            color: #E5F1FB;
        }
    }
}
</style>