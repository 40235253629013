<template>
    <div class="shenji-body">
        <leftContent  ref="leftContentRef"></leftContent>
        <amongContent ref="amongContentRef"></amongContent>
        <rightContent ref="rightContentRef"></rightContent>
    </div>
</template>

<script>
import leftContent from './shenji/leftContent.vue';
import rightContent from './shenji/rightContent.vue';
import amongContent from './amongContent.vue';
export default {
    name: 'shenJi',
    data() {
        return {

        }
    },
    components: {
        leftContent,
        rightContent,
        amongContent
    },
    methods: {
        fenData(type, item) {
            if (type < 16 && type != 7) {
                this.$refs.leftContentRef.setLeftData(type, item)
            }
            if (type > 15 || type == 7) {
                this.$refs.rightContentRef.setRightData(type, item)
            }
            if(type == 28){
                this.$refs.amongContentRef.xitongneikehuzongliang = item.xitongneikehuzongliang
                this.$refs.amongContentRef.kejifuwuguikouanjianshu = item.kejifuwuguikouanjianshu
                this.$refs.amongContentRef.zhishichanquanguikouanjianshu = item.zhishichanquanguikouanjianshu
                this.$refs.amongContentRef.jituanzongrenshu = item.jituanzongrenshu
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.shenji-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
</style>