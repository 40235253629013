<template>
    <div>
        <div class="small-title">
            撰写是否分配数量统计
        </div>
        <div class="audit-info">
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ fourInfo.weifenpei }}</div>
                <div class="audit-info-item-text">未分配</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ fourInfo.yifenpei }}</div>
                <div class="audit-info-item-text">已分配</div>
            </div>
        </div>
        <div class="small-title">
            是否为外部案卷数量统计
        </div>
        <div class="work-copyright-canvas" id="out-info">
        </div>
        <div class="small-title">
            保护中心预审是否通过数量统计
        </div>
        <div class="work-copyright-canvas" id="centre-info">
        </div>
    </div>
</template>

<script>
export default {
    name: 'patentRight',
    data() {
        return {
            fourInfo:{},
            fiveInfo:{},
            sixInfo:{}
        }
    },
    mounted() {
        this.getOutInfo()
    },
    methods: {
        setRightData(type,item){
            if(type == 42){
                this.fourInfo = item
            }
            if(type == 43){
                this.fiveInfo = item
                this.outInit()
            }
            if( type == 44 ){
                this.sixInfo  = item
                this.fiveInit()
            }
        },
        getOutInfo() {
            this.$axios.get(
                '/zhichanzhuanlishenqing/part4'
            ).then(res => {
                this.fourInfo = res.data
            })
            this.$axios.get(
                '/zhichanzhuanlishenqing/part5'
            ).then(res => {
                this.fiveInfo = res.data
                this.outInit()
            })
            this.$axios.get(
                '/zhichanzhuanlishenqing/part6'
            ).then(res => {
                console.log(res)
                this.sixInfo = res.data
                this.fiveInit()
            })
        },
        outInit() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var varEchart = this.$echarts.init(document.getElementById('out-info'));
            varEchart.setOption({
                series: [
                    {
                        avoidLabelOverlap: false,
                        type: 'pie',
                        // roseType: 'area', // 玫瑰图
                        center: ['45%', '50%'],
                        radius: ['30%', '50%'],
                        label: {
                            normal: {
                                formatter: '{c}\n————\n{b}',
                                textStyle: {
                                    fontSize: titleSize * 0.83
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                length: 10,
                                length2: 30
                            }
                        },
                        data: [{
                            name: '是',
                            value: Number(this.fiveInfo.shi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#62f4a8',

                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#62f4a8'
                                    }, {
                                        offset: 1,
                                        color: '#31e3ff'
                                    }])
                                }
                            }
                        }, {
                            name: '否',
                            value: Number(this.fiveInfo.fou),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#3a88fc'
                                    }])
                                }
                            }
                        }]
                    }
                ]
            })
        },
        fiveInit(){
            const titleSize = document.body.offsetWidth / 100
            var centreEchart = this.$echarts.init(document.getElementById('centre-info'));
            centreEchart.setOption({
                series: [
                    {
                        avoidLabelOverlap: false,
                        type: 'pie',
                        // roseType: 'area', // 玫瑰图
                        center: ['45%', '50%'],
                        radius: ['30%', '50%'],
                        label: {
                            normal: {
                                formatter: '{c}\n————\n{b}',
                                textStyle: {
                                    fontSize: titleSize * 0.83
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                length: 10,
                                length2: 30
                            }
                        },
                        data: [{
                            name: '是',
                            value: Number(this.sixInfo.shi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#62f4a8',

                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#3a88fc'
                                    }])
                                }
                            }
                        }, {
                            name: '否',
                            value: Number(this.sixInfo.fou),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#62f4a8'
                                    }, {
                                        offset: 1,
                                        color: '#31e3ff'
                                    }])
                                    
                                }
                            }
                        }]
                    }
                ]
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.audit-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 26.31vw;
    margin-top: 1.5rem;

    .audit-info-item {
        width: 11.25rem;
        height: 8.0625rem;
        background-image: url(../../assets/shenji/yishen.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;

        .audit-info-item-number {
            padding-top: 0.75rem;
            font-weight: 500;
            font-size: 1.25rem;
            color: #03E5F1;
            font-family: 'RobotoMedium';
        }

        .audit-info-item-text {
            margin-top: 1rem;
            font-weight: 500;
            font-size: 1rem;
            color: #E5F1FB;
        }
    }
}

.work-copyright-canvas {
    width: 26.31vw;
    height: 14.75rem;
    position: relative;
    background-image: url(../../assets/highTech/leftBg.png);
    background-size: 30% 60%;
    background-repeat: no-repeat;
    background-position: 42.5% 48%;
}
</style>