<template>
    <div class="all-body">
        <leftContent ref="technologyLeftRef"></leftContent>
        <amongContent ref="amongContentRef"></amongContent>
        <rightContent ref="technologyRightRef"></rightContent>
    </div>
</template>

<script>
import leftContent from '../components/technology/leftContent.vue';
import rightContent from '../components/technology/rightContent.vue';
import amongContent from '../components/amongContent.vue';
export default {
    name:'technology',
    data(){
        return{

        }
    },
    components: {
        leftContent,
        rightContent,
        amongContent
    },
    methods:{
        fenData(type,item){
            if (type > 49 && type <53) {
                this.$refs.technologyLeftRef.setLeftData(type, item)
            }
            if (type > 52 ) {
                this.$refs.technologyRightRef.setRightData(type, item)
            }
            if(type == 28){
                this.$refs.amongContentRef.xitongneikehuzongliang = item.xitongneikehuzongliang
                this.$refs.amongContentRef.kejifuwuguikouanjianshu = item.kejifuwuguikouanjianshu
                this.$refs.amongContentRef.zhishichanquanguikouanjianshu = item.zhishichanquanguikouanjianshu
                this.$refs.amongContentRef.jituanzongrenshu = item.jituanzongrenshu
            }
        }
    }
}
</script>

<style scoped lang="scss">
.all-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
</style>