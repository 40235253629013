<template>
    <div>
        <div class="small-title">
            高新案件金额
        </div>
        <div class="case-amount-canvas" id="case-amount"></div>
        <div class="small-title margingTop">
            高新案件管理
        </div>
        <div class="project-type">
            <div class="project-type-item">
                <div class="project-type-item-title">
                    案件数量指标
                </div>
                <div class="project-type-item-number">
                    {{ anjianshuliangzhibiao }}
                </div>
            </div>
            <div class="project-type-item">
                <div class="project-type-item-title">
                    产学研项目数量
                </div>
                <div class="project-type-item-number">
                    {{ chanxueyanxiangmushuliang }}
                </div>
            </div>
            <div class="project-type-item">
                <div class="project-type-item-title">
                    立项结题项目
                </div>
                <div class="project-type-item-number">
                    {{ lixiangjietixiangmu }}
                </div>
            </div>
        </div>
        <div class="small-title margingTop">
            签单金额信息展示
        </div>
        <div class="signed-amount">
            <div class="signed-amount-item">
                <div class="signed-amount-item-title">产学研签单金额</div>
                <div class="signed-amount-item-number">{{ moneyInfoData.chanxueyanqiandanjine }}</div>
                <div class="signed-amount-item-text" style="margin-top: 1rem;">
                    <p class="text-p">已到账金额</p>
                    <p class="text-number">{{ moneyInfoData.chanxueyanqiandanyidaozhangjine }}</p>
                </div>
                <div class="signed-amount-item-text">
                    <p>剩余尾款额</p>
                    <p>{{ moneyInfoData.chanxueyanqiandanshengyuweikuanejine }}</p>
                </div>
            </div>
            <div class="signed-amount-item">
                <div class="signed-amount-item-title">立项结题签单金额</div>
                <div class="signed-amount-item-number">{{ moneyInfoData.lixiangjietiqiandanjine }}</div>
                <div class="signed-amount-item-text" style="margin-top: 1rem;">
                    <p class="text-p">已到账金额</p>
                    <p class="text-number">{{ moneyInfoData.lixiangjietiqiandanyidaozhangjine }}</p>
                </div>
                <div class="signed-amount-item-text">
                    <p>剩余尾款额</p>
                    <p>{{ moneyInfoData.lixiangjietiqiandanshengyuweikuanejine }}</p>
                </div>
            </div>
            <div class="signed-amount-item marginTop-item">
                <div class="signed-amount-item-title">科小签单总金额</div>
                <div class="signed-amount-item-number">{{ moneyInfoData.kexiaoqiandanzongjine }}</div>
                <div class="signed-amount-item-text" style="margin-top: 1rem;">
                    <p class="text-p">已到账金额</p>
                    <p class="text-number">{{ moneyInfoData.kexiaoqiandanyidaozhangjine }}</p>
                </div>
                <div class="signed-amount-item-text">
                    <p>剩余尾款额</p>
                    <p>{{ moneyInfoData.kexiaoqiandanqiandanshengyuweikuanejine }}</p>
                </div>
            </div>
            <div class="signed-amount-item marginTop-item">
                <div class="signed-amount-item-title">年报签单总金额</div>
                <div class="signed-amount-item-number">{{ moneyInfoData.nianbaoqiandanzongjine }}</div>
                <div class="signed-amount-item-text" style="margin-top: 1rem;">
                    <p class="text-p">已到账金额</p>
                    <p class="text-number">{{ moneyInfoData.nianbaoqiandanyidaozhangjine }}</p>
                </div>
                <div class="signed-amount-item-text">
                    <p>剩余尾款额</p>
                    <p>{{ moneyInfoData.nianbaoqiandanqiandanshengyuweikuanejine }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'highTechLeft',
    data() {
        return {
            qiandanjine: 0,
            shengyuweikuanjine: 0,
            yidaozhangjine: 0,
            anjianshuliangzhibiao: 0,
            chanxueyanxiangmushuliang: 0,
            lixiangjietixiangmu: 0,
            moneyInfoData:{}
        }
    },
    mounted() {
        this.getCaseAmountInfo()
        this.getGaoxinAnjianInfo()
        this.getMoneyInfo()
    },
    methods: {
        setLeftData(type,item){
            if(type == 29){
                // console.log(item)
                this.qiandanjine = item.setRightData,
                this.shengyuweikuanjine = item.shengyuweikuanjine
                this.yidaozhangjine = item.yidaozhangjine
                this.caseAmountInit()
            }
            if(type == 30){
                this.anjianshuliangzhibiao = item.anjianshuliangzhibiao,
                this.chanxueyanxiangmushuliang = item.chanxueyanxiangmushuliang
                this.lixiangjietixiangmu = item.lixiangjietixiangmu
            }
            if(type == 31){
                this.moneyInfoData.chanxueyanqiandanjine = item.chanxueyanqiandanjine,
                this.moneyInfoData.chanxueyanqiandanyidaozhangjine = item.chanxueyanqiandanyidaozhangjine
                this.moneyInfoData.chanxueyanqiandanshengyuweikuanejine = item.chanxueyanqiandanshengyuweikuanejine
                this.moneyInfoData.lixiangjietiqiandanjine = item.lixiangjietiqiandanjine
                this.moneyInfoData.lixiangjietiqiandanyidaozhangjine = item.lixiangjietiqiandanyidaozhangjine
                this.moneyInfoData.lixiangjietiqiandanshengyuweikuanejine = item.lixiangjietiqiandanshengyuweikuanejine
                this.moneyInfoData.kexiaoqiandanzongjine  = item.kexiaoqiandanzongjine
                this.moneyInfoData.kexiaoqiandanyidaozhangjine = item.kexiaoqiandanyidaozhangjine
                this.moneyInfoData.kexiaoqiandanqiandanshengyuweikuanejine=item.kexiaoqiandanqiandanshengyuweikuanejine
                this.moneyInfoData.nianbaoqiandanzongjine = item.nianbaoqiandanzongjine
                this.moneyInfoData.nianbaoqiandanyidaozhangjine = item.nianbaoqiandanyidaozhangjine
                this.moneyInfoData.nianbaoqiandanqiandanshengyuweikuanejine = item.nianbaoqiandanqiandanshengyuweikuanejine
            }
        },
        getCaseAmountInfo() {
            this.$axios.get(
                '/kejifuwugaoxin/part1'
            ).then(res => {
                this.qiandanjine = res.data.qiandanjine
                this.shengyuweikuanjine = res.data.shengyuweikuanjine
                this.yidaozhangjine = res.data.yidaozhangjine
                this.caseAmountInit()

            })

        },
        getGaoxinAnjianInfo(){
            this.$axios.get(
                '/kejifuwugaoxin/part2'
            ).then(res => {
                this.anjianshuliangzhibiao =res.data.anjianshuliangzhibiao
                this.chanxueyanxiangmushuliang = res.data.chanxueyanxiangmushuliang
                this.lixiangjietixiangmu = res.data.lixiangjietixiangmu
            })
        }, 
        getMoneyInfo(){
            this.$axios.get(
                '/kejifuwugaoxin/part3'
            ).then(res => {
                this.moneyInfoData = res.data
            })
        },
        caseAmountInit() {
            const titleSize = document.body.offsetWidth / 100
            // console.log(titleSize)
            // 基于准备好的dom，初始化echarts实例
            var xiangmuState = this.$echarts.init(document.getElementById('case-amount'));
            xiangmuState.setOption({
                title: {
                    show: false
                },
                tooltip: {},
                xAxis: {
                    type: 'category',
                    data: ['签单金额(元)', '已到账金额(元)', '剩余尾款金额(元)'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#004880'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83,
                        interval: 0
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#34475D'
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                series: [
                    {
                        name: '元',
                        type: 'bar',
                        data: [this.qiandanjine, this.yidaozhangjine, this.shengyuweikuanjine],
                        barWidth: '10%',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 0, color: '#09D7FD' },
                                        { offset: 1, color: '#3A88FC' }
                                    ]
                                )
                            }
                        }
                    }
                ],
                grid: {
                    top: '10%',
                    bottom: '20%',
                    left: '20%',
                    right: '0'
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.case-amount-canvas {
    width: 23.75vw;
    height: 14.53vh;
    margin-top: 1rem;
}

.margingTop {
    margin-top: 2rem;
}

.project-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 26.6vw;

    .project-type-item {
        background-image: url(../../assets/highTech/bgimg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 10.625rem;
        height: 10.625rem;
        text-align: center;

        .project-type-item-title {
            padding-top: 2.3rem;
            font-size: 1rem;
            color: #e5f1fb;
        }

        .project-type-item-number {
            margin-top: 1.5rem;
            color: #e5f1fb;
            font-size: 1.25rem;
            font-family: 'RobotoMedium';
        }
    }
}

.signed-amount {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 26.6vw;

    .signed-amount-item {
        width: 12.9375rem;
        height: 8.25rem;
        background-image: url(../../assets/highTech/moneyBg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 1.5rem;

        .signed-amount-item-title {
            font-size: 1rem;
            color: #e5f1fb;
            margin-top: -0.6rem;
            text-align: center;
        }

        .signed-amount-item-number {
            margin-top: 1.4rem;
            font-size: 1.25rem;
            color: #36F3FE;
            font-weight: bold;
            text-align: center;
        }

        .signed-amount-item-text {
            font-size: 1rem;
            color: #e5f1fb;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .text-number {
                color: #22CD92;
            }
        }

    }

    .marginTop-item {
        margin-top: 2rem;
    }
}
</style>