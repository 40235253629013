<template>
    <div class="">
        <div class="small-title">
            状态项目数量
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">状态项目趋势</div>
                <div class="project-status-title-line"></div>
            </div>
            <div class="project-status-canvas" id="project-statusID"></div>
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">目前项目进度数量图</div>
                <div class="project-status-title-line"></div>
            </div>
            <div class="project-status-canvas" id="project-progressID"></div>
        </div>
        <div class="small-title margingTop">
            项目类型统计
        </div>
        <div class="project-type">
            <div class="project-type-item">
                <div class="project-type-item-title">
                    目前项目数量
                </div>
                <div class="project-type-item-number">
                    {{ projectTypeList.muqianxiangmushuliang }}
                </div>
            </div>
            <div class="project-type-item">
                <div class="project-type-item-title">
                    专项业务数量
                </div>
                <div class="project-type-item-number">
                    {{ projectTypeList.zhuanxiangyewushuliang }}
                </div>
            </div>
            <div class="project-type-item">
                <div class="project-type-item-title">
                    紧急处理项目
                </div>
                <div class="project-type-item-number">
                    {{ projectTypeList.jinjichulixiangmu }}
                </div>
            </div>
            <div class="project-type-item">
                <div class="project-type-item-title">
                    年审业务数量
                </div>
                <div class="project-type-item-number">
                    {{ projectTypeList.nianshenyewushuliang }}
                </div>
            </div>
            <div class="project-type-item">
                <div class="project-type-item-title">
                    辅助账业务数量
                </div>
                <div class="project-type-item-number">
                    {{ projectTypeList.fuzhuzhangyewushuliang }}
                </div>
            </div>
            <div class="project-type-item">
                <div class="project-type-item-title">
                    企业问题数量
                </div>
                <div class="project-type-item-number">
                    {{ projectTypeList.qiyewentishuliang }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "shenJiLeft",
    data() {
        return {
            jinxingzhong: 0,
            xiangmuzanting: 0,
            chongqixiangmu: 0,
            dataCenterInfo: {
                baogaozhuanxiezhong: 0,
                dinggaozhong: 0,
                ershen: 0,
                xiangmuxinxi: 0,
                yishen: 0
            },
            projectTypeList: {
                fuzhuzhangyewushuliang: 0,
                jinjichulixiangmu: 0,
                muqianxiangmushuliang: 0,
                nianshenyewushuliang: 0,
                qiyewentishuliang: 0,
                zhuanxiangyewushuliang: 0
            }
        }
    },
    mounted() {
        this.getProjectTypeData()
        this.getProjectInfo()
        this.getCenterDataInfo()
    },
    methods: {
        setLeftData(type, item) {
            if (type == 1) {
                this.projectTypeList.muqianxiangmushuliang = item.muqianxiangmushuliang
            }
            if (type == 2) {
                this.projectTypeList.qiyewentishuliang = item.qiyewentishuliang
            }
            if (type == 3) {
                this.projectTypeList.jinjichulixiangmu = item.jinjichulixiangmu
            }
            if (type == 4) {
                this.projectTypeList.zhuanxiangyewushuliang = item.zhuanxiangyewushuliang
            }
            if (type == 5) {
                this.projectTypeList.nianshenyewushuliang = item.nianshenyewushuliang
            }
            if (type == 6) {
                this.projectTypeList.fuzhuzhangyewushuliang = item.fuzhuzhangyewushuliang
            }
            if (type == 8) {
                this.chongqixiangmu = Number(item.chongqixiangmu)
                this.projectInit()
            }
            if (type == 9) {
                this.xiangmuzanting = Number(item.xiangmuzanting)
                this.projectInit()
            }
            if (type == 10) {
                this.jinxingzhong = Number(item.jinxingzhong)
                this.projectInit()
            }
            if (type > 10) {
                if (type == 11) this.dataCenterInfo.yishen = Number(item.yishen)
                if (type == 12) this.dataCenterInfo.xiangmuxinxi = Number(item.xiangmuxinxi)
                if (type == 13) this.dataCenterInfo.ershen = Number(item.ershen)
                if (type == 14) this.dataCenterInfo.dinggaozhong = Number(item.dinggaozhong)
                if (type == 15) this.dataCenterInfo.baogaozhuanxiezhong = Number(item.baogaozhuanxiezhong)
                this.progressInit()
            }

        },
        getProjectTypeData() {
            this.$axios.get(
                '/kejifuwushenji/part1'
            ).then(res => {
                this.projectTypeList.fuzhuzhangyewushuliang = res.data.fuzhuzhangyewushuliang
                this.projectTypeList.jinjichulixiangmu = res.data.jinjichulixiangmu
                this.projectTypeList.muqianxiangmushuliang = res.data.muqianxiangmushuliang
                this.projectTypeList.nianshenyewushuliang = res.data.nianshenyewushuliang
                this.projectTypeList.qiyewentishuliang = res.data.qiyewentishuliang
                this.projectTypeList.zhuanxiangyewushuliang = res.data.zhuanxiangyewushuliang
            })
        },
        getProjectInfo() {
            this.$axios.get(
                '/kejifuwushenji/part3'
            ).then(res => {
                this.xiangmuzanting = res.data.xiangmuzanting
                this.jinxingzhong = res.data.jinxingzhong
                this.chongqixiangmu = res.data.chongqixiangmu
                this.projectInit()
            })
        },
        getCenterDataInfo() {
            this.$axios.get(
                '/kejifuwushenji/part4'
            ).then(res => {
                this.dataCenterInfo.baogaozhuanxiezhong = res.data.baogaozhuanxiezhong
                this.dataCenterInfo.dinggaozhong = res.data.dinggaozhong
                this.dataCenterInfo.ershen = res.data.ershen
                this.dataCenterInfo.xiangmuxinxi = res.data.xiangmuxinxi
                this.dataCenterInfo.yishen = res.data.yishen
                this.progressInit()
            })
        },
        projectInit() {
            const titleSize = document.body.offsetWidth / 100
            // console.log(titleSize)
            // 基于准备好的dom，初始化echarts实例
            var xiangmuState = this.$echarts.init(document.getElementById('project-statusID'));
            xiangmuState.setOption({
                title: {
                    show: false
                },
                tooltip: {},
                xAxis: {
                    type: 'category',
                    data: ['进行中', '项目暂停', '重启项目'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#004880'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#34475D'
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        data: [this.jinxingzhong, this.xiangmuzanting, this.chongqixiangmu],
                        barWidth: '10%',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 0, color: '#09D7FD' },
                                        { offset: 1, color: '#3A88FC' }
                                    ]
                                )
                            }
                        }
                    }
                ],
                grid: {
                    top: '10%',
                    bottom: '20%',
                    left: '10%',
                    right: '0'
                }
            });
        },
        progressInit() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var xiangmuState = this.$echarts.init(document.getElementById('project-progressID'));
            // 绘制图表
            xiangmuState.setOption({
                title: {
                    show: false
                },
                tooltip: {},
                yAxis: {

                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#34475D'
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                xAxis: {
                    type: "category",
                    data: ['一审', '项目信息', '二审', '定稿中', '报告撰写中'],

                    axisLine: {
                        show: true,

                        lineStyle: {
                            color: '#004880'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: true,
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83,
                        interval: 0,
                    },


                },
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        data: [this.dataCenterInfo.yishen, this.dataCenterInfo.xiangmuxinxi, this.dataCenterInfo.ershen, this.dataCenterInfo.dinggaozhong, this.dataCenterInfo.baogaozhuanxiezhong],
                        barWidth: '13%',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 0, color: '#09D7FD' },
                                        { offset: 1, color: '#3A88FC' }
                                    ]
                                )
                            }
                        }
                    }
                ],
                grid: {
                    top: '10%',
                    bottom: '20%',
                    left: '10%',
                    right: '0'
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.margingTop {
    margin-top: 2.93rem;
}

.project-status {
    margin-top: 1rem;
    width: 26.31vw;

    .project-status-title {
        display: flex;
        align-items: center;
        justify-content: center;

        &-text {
            font-size: 1.125rem;
            color: #66D9FF;
            margin: 0 0.3125rem;
        }

        &-line {
            height: 0.125rem;
            width: 2.68rem;
            background-color: #66D9FF;
        }
    }

    .project-status-canvas {
        width: 100%;
        height: 15.37vh;
    }
}

.project-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 26.6vw;

    .project-type-item {
        background-image: url(../../assets/shenji/item-bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 10.625rem;
        height: 10.625rem;
        text-align: center;

        .project-type-item-title {
            padding-top: 2.3rem;
            font-size: 1rem;
            color: #e5f1fb;
        }

        .project-type-item-number {
            margin-top: 1.5rem;
            color: #e5f1fb;
            font-size: 1.25rem;
            font-family: 'RobotoMedium';
        }
    }
}
</style>