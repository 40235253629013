<template>
    <div class="all-body">
        <leftContent ref="highTechLeft"></leftContent>
        <amongContent ref="amongContentRef"></amongContent>
        <rightContent ref="highTechRight"></rightContent>
    </div>
</template>

<script>
import leftContent from '../components/highTech/leftContent.vue';
import rightContent from '../components/highTech/rightContent.vue';
import amongContent from '../components/amongContent.vue';
export default {
    name: "highTech",
    data() {
        return {

        }
    },
    components: {
        leftContent,
        rightContent,
        amongContent
    },
    methods:{
        fenData(type, item) {
            if(type == 28){
                this.$refs.amongContentRef.xitongneikehuzongliang = item.xitongneikehuzongliang
                this.$refs.amongContentRef.kejifuwuguikouanjianshu = item.kejifuwuguikouanjianshu
                this.$refs.amongContentRef.zhishichanquanguikouanjianshu = item.zhishichanquanguikouanjianshu
                this.$refs.amongContentRef.jituanzongrenshu = item.jituanzongrenshu
            }
            else if(type > 28 && type <32){
                this.$refs.highTechLeft.setLeftData(type,item)
            }
            else{
                this.$refs.highTechRight.setRightData(type,item)
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.all-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
</style>