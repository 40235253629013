<template>
    <div>
        <div class="small-title">
            招聘状态统计
        </div>
        <div class="recruitment-status">
            <div class="status-item">
                <div class="item-title">{{ jinxingzhong }}</div>
                <div class="item-type">进行中</div>
                <div class="item-text">招聘状态</div>
            </div>
            <div class="status-item">
                <div class="item-title">{{ yiwancheng }}</div>
                <div class="item-type">已完成</div>
                <div class="item-text">招聘状态</div>
            </div>
            <div class="status-item">
                <div class="item-title">{{ zhongzhi }}</div>
                <div class="item-type">终止</div>
                <div class="item-text">招聘状态</div>
            </div>
        </div>
        <div class="small-title marginTop">
            招聘进度堆叠图
        </div>
        <div class="canvas-header">
            <div class="header-item">
                <div class="item-kuai"></div>
                <div class="item-text">需求数量</div>
            </div>
            <div class="header-item">
                <div class="item-kuai" style="background: #31E4FF;"></div>
                <div class="item-text">已入职人数</div>
            </div>
        </div>
        <div class="canvas-header">
            <div class="header-item">
                <div class="item-kuai" style="background: #85F7FF;"></div>
                <div class="item-text">待入职人数</div>
            </div>
            <div class="header-item">
                <div class="item-kuai" style="background: #F54040;"></div>
                <div class="item-text">试用期离职人数</div>
            </div>
        </div>
        <div class="recruitment-progress-canvas" id="recruitment-progress">

        </div>
    </div>
</template>

<script>
export default {
    name: 'manpowerRight',
    data() {
        return {
            timer: null,
            zhongzhi: 0,
            yiwancheng: 0,
            jinxingzhong: 0,
            categoryData: [],
            yiruzhirenshu: [],
            xuqiushuliang: [],
            shiyongqilizhirenshu: [],
            dairuzhirenshu: []
        }
    },
    mounted() {
        this.getCanvasInfo()
        this.getTypeInfo()
    },
    methods: {
        setManRightData(item, type) {
            if (type == 25) {
                this.jinxingzhong = item.jinxingzhong
                this.yiwancheng = item.yiwancheng
                this.zhongzhi = item.zhongzhi
            }
            if (type == 27) {
                item.forEach(element => {
                    this.$set(this.categoryData, this.categoryData.length, element.zhaopinbumen)
                    this.$set(this.dairuzhirenshu, this.dairuzhirenshu.length, element.dairuzhirenshu)
                    this.$set(this.shiyongqilizhirenshu, this.shiyongqilizhirenshu.length, element.shiyongqilizhirenshu)
                    this.$set(this.xuqiushuliang, this.xuqiushuliang.length, element.xuqiushuliang)
                });
                this.canvasInit()
            }
        },
        getTypeInfo() {
            this.$axios.get(
                '/renliziyuanguanli/part2'
            ).then(res => {
                this.jinxingzhong = res.data.jinxingzhong
                this.yiwancheng = res.data.yiwancheng
                this.zhongzhi = res.data.zhongzhi
            })
        },
        getCanvasInfo() {
            this.$axios.get(
                '/renliziyuanguanli/part4'
            ).then(res => {
                if (res.data.length != 0) {
                    res.data.map(item => {
                        this.$set(this.categoryData, this.categoryData.length, item.zhaopinbumen)
                        this.$set(this.dairuzhirenshu, this.dairuzhirenshu.length, item.dairuzhirenshu)
                        this.$set(this.shiyongqilizhirenshu, this.shiyongqilizhirenshu.length, item.shiyongqilizhirenshu)
                        this.$set(this.xuqiushuliang, this.xuqiushuliang.length, item.xuqiushuliang)
                    })
                    this.canvasInit()
                }
            })

        },
        canvasInit() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var varEchart = this.$echarts.init(document.getElementById('recruitment-progress'));
            var categoryData = this.categoryData
            var end = 0
            if (categoryData.length < 5) {
                end = categoryData.length - 1;
            } else {
                end = 7;
            }
            let option = {
                grid: {
                    top: '10%',
                    bottom: '20%',
                    left: '30%',
                    right: '5%'
                },
                xAxis: [{
                    type: "value",
                    trigger: "axis",
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: true,
                        interval: 0,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: titleSize * 0.83
                        }
                    },
                }],
                yAxis: [{
                    type: "category",
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        "interval": 0,
                        fontSize: titleSize * 0.83
                    },

                    data: categoryData,
                }],
                dataZoom: [
                    {
                        show: false,
                        type: 'slider',
                        yAxisIndex: 0,
                        zoomLock: false,
                        width: 8,
                        showDetail: false,
                        startValue: 0, // 从头开始。
                        endValue: end, // 一次性展示五个。
                        borderWidth: 0,
                        borderColor: 'transparent',
                        backgroundColor: '#343F4B',
                        fillerColor: '#4291CE',
                        showDataShadow: false,
                        brushSelect: false,
                    },
                    {
                        //没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条
                        type: "inside",
                        yAxisIndex: [0, 1],//控制哪个轴，如果是 number 表示控制一个轴，如果是 Array 表示控制多个轴。此处控制第二根轴
                        zoomOnMouseWheel: false, //滚轮是否触发缩放
                        moveOnMouseMove: true, //鼠标移动能否触发平移
                        moveOnMouseWheel: true,//鼠标滚轮能否触发平移
                    },
                ],
                "series": [{
                    "name": "需求数量",
                    "type": "bar",
                    borderWidth: titleSize,
                    "stack": "总量",
                    "barMaxWidth": 15,
                    "barGap": "10%",
                    "itemStyle": {
                        normal: {
                            color: "#3A88FC"
                        }
                    },
                    "data": this.xuqiushuliang,
                },
                {
                    "name": "已入职人数",
                    "type": "bar",
                    borderWidth: titleSize,
                    "stack": "总量",
                    "itemStyle": {
                        normal: {
                            color: "#31E4FF"
                        }
                    },
                    "data": this.yiruzhirenshu,
                },
                {
                    "name": "待入职人数",
                    "type": "bar",
                    "stack": "总量",
                    borderWidth: titleSize,
                    "itemStyle": {
                        normal: {
                            color: "#85F7FF"
                        }
                    },
                    "data": this.dairuzhirenshu,
                },
                {
                    "name": "试用期离职人数",
                    "type": "bar",
                    "stack": "总量",
                    borderWidth: titleSize,
                    "itemStyle": {
                        normal: {
                            color: "#F54040"
                        }
                    },
                    "data": this.shiyongqilizhirenshu,
                }
                ]
            }
            this.timer = setInterval(() => {
                if (Number(option.dataZoom[0].endValue) === categoryData.length - 1) {
                    option.dataZoom[0].endValue = end;
                    option.dataZoom[0].startValue = 0;
                } else {
                    option.dataZoom[0].endValue = option.dataZoom[0].endValue + 1;
                    option.dataZoom[0].startValue = option.dataZoom[0].startValue + 1;
                }
                varEchart.setOption(option);
            }, 3000);
            varEchart.setOption(option);
        }
    },
    beforeDestory() {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>

<style scoped lang="scss">
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.recruitment-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3.125rem;

    .status-item {
        width: 8.9375rem;
        height: 11.75rem;
        background-image: url(../../assets/highTech/typeBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        flex-direction: column;

        .item-title {
            padding-top: 1.5rem;
            font-size: 1.25rem;
            color: $fontWhiteColor;
            font-weight: bold;
        }

        .item-type {
            margin-top: 1rem;
            font-size: 1rem;
            color: #31E3FF;
        }

        .item-text {
            color: $fontWhiteColor;
            font-size: 1rem;
            margin-top: 1.5rem;
        }
    }
}

.marginTop {
    margin-top: 1.5rem;
}

.canvas-header {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    padding-left: 9.0625rem;

    .header-item {
        display: flex;
        align-items: center;
        flex: 1;
    }

    .item-kuai {
        width: 0.625rem;
        height: 0.625rem;
        background: #3A88FC;
        margin-right: 0.625rem;
    }

    .item-text {
        font-size: 1rem;
        color: $fontWhiteColor;
    }
}

.recruitment-progress-canvas {
    width: 26.31vw;
    height: 42.78vh;
}
</style>