<template>
    <div>
        <div class="small-title">
            招聘项目各中心占比
        </div>
        <div class="work-copyright-canvas" id="recruitment-project">
        </div>
        <div class="small-title">
            招聘类型所需数量
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">岗位招聘数量信息</div>
                <div class="project-status-title-line"></div>
            </div>
        </div>
        <div class="tableOut">
            <div class="tableTit">
                <div class="table-time title-size">招聘时间</div>
                <div class="table-name title-size">招聘部门</div>
                <div class="table-number title-size">人数</div>
            </div>
            <vue-seamless-scroll :data="listData" :class-option="classOption" class="tableBox">
                <ul class="item">
                    <li v-for="item in listData" class="tableBox-item">
                        <span class="table-time">{{ item.zhaopinshijian.substr(0,10) }}</span>
                        <span class="table-text">{{ item.zhaopinbumen }}</span>
                        <span class="table-number" style="color: #36F3FE;">{{ item.renshu }}</span>
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>
    </div>
</template>

<script>
export default {
    name: 'manpowerLeft',
    data() {
        return {
            listData: [{
                title: '极山云前端招聘',
                zhaopinshijian: '02-11 11:28',
                num:7
            }, {
                title: '极山云前端招聘',
                zhaopinshijian: '02-11 11:29',
                num:7
            }, {
                title: '极山云前端招聘',
                zhaopinshijian: '02-11 11:30',
                num:7
            }, {
                title: '极山云前端招聘',
                zhaopinshijian: '02-11 11:31',
                num:7
            }, {
                title: '极山云前端招聘',
                zhaopinshijian: '02-11 11:32',
                num:7
            }, {
                title: '极山云前端招聘',
                zhaopinshijian: '02-11 11:33',
                num:7
            },
            {
                title: '极山云前端招聘',
                zhaopinshijian: '02-11 11:34',
                num:7
            },
            {
                title: '极山云前端招聘',
                zhaopinshijian: '02-11 11:35',
                num:7
            },
            {
                title: '极山云前端招聘',
                zhaopinshijian: '02-11 11:36',
                num:7
            }],
            classOption:{
                step: .5,
                hoverStop:false,
                limitMoveNum:1
            },
            chanpinzhongxinbaifenbi:0,
            chuangxinfazhanzhongxinbaifenbi:0,
            xiaoshouzhongxinbaifenbi:0
        }
    },
    mounted() {
        this.getWorkInfo()
        this.getList()
    },
    methods: {
        setManLeftData(type,item){
            console.log(item)
            if(type == 24){
                this.xiaoshouzhongxinbaifenbi = item.xiaoshouzhongxinbaifenbi
                this.chanpinzhongxinbaifenbi = item.chanpinzhongxinbaifenbi
                this.chuangxinfazhanzhongxinbaifenbi = item.chuangxinfazhanzhongxinbaifenbi
                this.workInfoInit()
            }
            if(type == 26){
                this.listData = [...this.listData,...item]
            }
        },
        getWorkInfo(){
            this.$axios.get(
                '/renliziyuanguanli/part1'
            ).then(res => {
                this.xiaoshouzhongxinbaifenbi = res.data.xiaoshouzhongxinbaifenbi
                this.chuangxinfazhanzhongxinbaifenbi= res.data.chuangxinfazhanzhongxinbaifenbi
                this.chanpinzhongxinbaifenbi = res.data.chanpinzhongxinbaifenbi
                this.workInfoInit()
            })
            
        },
        getList(){
            this.$axios.get(
                '/renliziyuanguanli/part3'
            ).then(res => {
                this.listData = res.data
            })
        },
        workInfoInit() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var varEchart = this.$echarts.init(document.getElementById('recruitment-project'));
            varEchart.setOption({
                series: [
                    {
                        avoidLabelOverlap: false,
                        type: 'pie',
                        roseType: 'area', // 玫瑰图
                        center: ['45%', '50%'],
                        radius: ['20%', '50%'],
                        label: {
                            normal: {
                                formatter: '{c}%\n————\n{b}',
                                textStyle: {
                                    fontSize: titleSize * 0.83
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                length: 10,
                                length2: 30
                            }
                        },
                        data: [{
                            name: '销售中心',
                            value: Number(this.xiaoshouzhongxinbaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#62f4a8',

                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#62f4a8'
                                    }, {
                                        offset: 1,
                                        color: '#31e3ff'
                                    }])
                                }
                            }
                        }, {
                            name: '产品中心',
                            value: Number(this.chanpinzhongxinbaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#3a88fc'
                                    }])
                                }
                            }
                        }, {
                            name: '创新发展中心',
                            value: Number(this.chuangxinfazhanzhongxinbaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#69bbff'
                                    }])
                                }
                            }
                        }]
                    }
                ]
            })

        }
    }
}
</script>

<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.work-copyright-canvas {
    width: 26.31vw;
    height: 14.75rem;
    position: relative;
    background-image: url(../../assets/highTech/leftBg.png);
    background-size: 30% 60%;
    background-repeat: no-repeat;
    background-position: 42% 48%;
}

.project-status {
    margin-top: 1rem;
    width: 26.31vw;

    .project-status-title {
        display: flex;
        align-items: center;
        justify-content: center;

        &-text {
            font-size: 1.125rem;
            color: #66D9FF;
            margin: 0 0.3125rem;
        }

        &-line {
            height: 0.125rem;
            width: 2.68rem;
            background-color: #66D9FF;
        }
    }
}

.tableOut {
    width: 26.31vw;
    height: 44.07vh;
    overflow: hidden;
    margin-top: 1rem;

    .tableTit {
        padding: 0.875rem 1.6875rem;
        background-image: url(../../assets/highTech/tableBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .table-time {
        color: $fontWhiteColor;
        font-size: 1.125rem;
        width: 9.375rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .table-name {
        color: $fontWhiteColor;
        font-size: 1.125rem;
        width: 13.875rem;
        padding-left: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .table-text{
        color: $fontWhiteColor;
        font-size: 1rem;
        width: 13.875rem;
        padding-left: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .title-size{
        font-size: 1rem !important;
    }
    .table-number {
        color: $fontWhiteColor;
        font-size: 1.125rem;
        width: 3.625rem;
        padding-left: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tableBox {
        height: 27rem;
        overflow: hidden;

        .tableBox-item {
            padding: 0.875rem 1.6875rem;
            background-image: url(../../assets/highTech/tableItemBg.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
</style>