<template>
    <div>
        <div class="small-title">
            商标业务数量统计
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">商标、作品版权数量信息</div>
                <div class="project-status-title-line"></div>
            </div>
            <div class="project-status-canvas" id="work-copyright-right"></div>
        </div>
        <div class="small-title margingTop">
            作品版权业务数量统计
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">商标业务数量统计</div>
                <div class="project-status-title-line"></div>
            </div>
        </div>
        <div class="di-item">
            <div class="item-div">
                <div>{{ fiveInfo.shangbiaozhuce }}</div>
                <div class="item-text">商标注册</div>
            </div>
            <div class="item-div">
                <div>{{ fiveInfo.shangbiaofeizhuce }}</div>
                <div class="item-text">商标非注册</div>
            </div>
            <div class="item-div">
                <div>{{ fiveInfo.shangbiaopingshen }}</div>
                <div class="item-text">商标评审</div>
            </div>
            <div class="item-div">
                <div>{{ fiveInfo.shangbiaoshewai }}</div>
                <div class="item-text">商标涉外</div>
            </div>
            <div class="item-div">
                <div>{{ fiveInfo.zuopinbanquan }}</div>
                <div class="item-text">作品版权</div>
            </div>
            <div class="item-div">
                <div>{{ fiveInfo.shangbiaoqita }}</div>
                <div class="item-text">商标其他</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'knowledgeRight',
    data() {
        return {
            fourInfo:{},
            fiveInfo:{}
        }
    },
    mounted() {
        this.getShangbiaoNumber()
    },
    methods: {
        setRightData(type,item){
            if(type == 48){
                this.fourInfo = item
                this.initZhu()
            }
            if(type == 49){
                this.fiveInfo = item
            }
        },
        getShangbiaoNumber() {
            this.$axios.get(
                '/zhichanshangbiaobanquan/part4'
            ).then(res => {
                this.fourInfo = res.data
                this.initZhu()
            })
            this.$axios.get(
                '/zhichanshangbiaobanquan/part5'
            ).then(res => {
                console.log(res)
                this.fiveInfo = res.data
            })
        },
        initZhu() {
            var titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var xiangmuState = this.$echarts.init(document.getElementById('work-copyright-right'));
            // 绘制图表
            xiangmuState.setOption({
                title: {
                    show: false
                },
                tooltip: {},
                xAxis: {
                    type: 'value',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        },
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        color: '#E5F1FB',
                        fontSize: titleSize*0.83
                    },
                },
                yAxis: {
                    type: 'category',
                    data: ['作品版权归档数量', '作品版权进行数量','商标待重启数量','商标归档数量', '商标进行数量'  ],

                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#E5F1FB',
                        fontSize: titleSize*0.83
                    },


                },
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        data: [this.fourInfo.zuopinbanquanguidang, this.fourInfo.zuopinbanquanjinxing, this.fourInfo.shangbiaodaichongqi, this.fourInfo.shangbiaoguidang, this.fourInfo.shangbiaojinxing],
                        barWidth: '36%',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 0, 1, 0,
                                    [
                                        { offset: 0, color: '#009FF3' },
                                        { offset: 1, color: '#04F6CC' }
                                    ]
                                )
                            }
                        }
                    }
                ],
                grid: {
                    top: '10%',
                    bottom: '20%',
                    left: '30%'
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.margingTop {
    margin-top: 2rem;
}

.project-status {
    margin-top: 1rem;
    width: 26.31vw;

    .project-status-title {
        display: flex;
        align-items: center;
        justify-content: center;

        &-text {
            font-size: 1.125rem;
            color: #66D9FF;
            margin: 0 0.3125rem;
        }

        &-line {
            height: 0.125rem;
            width: 2.68rem;
            background-color: #66D9FF;
        }
    }

    .project-status-canvas {
        width: 100%;
        height: 20rem;
    }
}
.di-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 26.31vw;
    .item-div{
        width: 8.625rem;
        height: 8.625rem;
        background-image: url(../../assets/highTech/diBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 1.25rem;
        font-weight: bold;
        color: $fontWhiteColor;
        .item-text{
            color: #00F4FE;
            font-weight: 400;
            font-size: 1rem;
        }
    }
}
</style>