<template>
    <div>
        <div class="small-title">
            专利流程管理
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">案件各阶段统计</div>
                <div class="project-status-title-line"></div>
            </div>
            <div class="client-zhu-canvas" id="case-stage"></div>
        </div>
        <div class="project-status" style="margin-top: 0;">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">预审状态统计</div>
                <div class="project-status-title-line"></div>
            </div>
            <div class="client-zhu-canvas" id="prequalification-status"></div>
        </div>
        <div class="small-title">
            代理师日报
        </div>
        <div class="ribao">
            <div class="ribao-item">
                <div class="ribao-image">
                    <img src="../../assets/shenji/ribao.png" style="width: 100%;height: 100%;" alt="">
                </div>
                <div class="ribao-right">
                    <div class="ribao-time">{{ today }}</div>
                    <div class="ribao-number"> {{ threeInfo.jintian }} <p class="ribao-text">(份)</p>
                    </div>
                    <div class="ribao-little">代理师今天日报</div>
                </div>
            </div>
            <div class="ribao-item ">
                <div class="ribao-image">
                    <img src="../../assets/shenji/zuoribao.png" style="width: 100%;height: 100%;" alt="">
                </div>
                <div class="ribao-right">
                    <div class="ribao-time">{{ yesterday }}</div>
                    <div class="ribao-number">{{ threeInfo.zuotian }} <p class="ribao-text">(份)</p>
                    </div>
                    <div class="ribao-little">代理师昨日日报</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'patentLeft',
    data() {
        return {
            oneInfo: {},
            twoInfo: {},
            threeInfo: {},
            today:null,
            yesterday:null
        }
    },
    mounted() {
        this.getInfo()
        this.getTime()
    },
    methods: {
        setLeftData(type,item){
            if(type == 39){
                this.oneInfo.xinjiananjian = item.xinjiananjian
                this.oneInfo.zhuanxiejieduan = item.zhuanxiejieduan
                this.oneInfo.zhijianjieduan = item.zhijianjieduan
                this.oneInfo.kehujieduan = item.kehujieduan
                this.oneInfo.yushenjieduan = item.yushenjieduan
                this.oneInfo.anjianwanjie = item.anjianwanjie
                this.init()
            }
            if( type == 40 ){
                this.twoInfo.yushenweiguozhuanweiputongb3 = item.yushenweiguozhuanweiputongb3
                this.twoInfo.yushenmeiguoxutuikuan = item.yushenmeiguoxutuikuan
                this.zhuanweiputong = item.zhuanweiputong
                this.towInit()
            }
            if( type == 41 ){
                this.threeInfo.jintian = item.jintian
                this.threeInfo.zuotian = item.zuotian
            }
        },
        getTime() {
            this.today = new Date(parseInt(+new Date())).toLocaleDateString().replace(/[/]+/g, '-')
            this.yesterday = new Date(parseInt(+new Date() - 3600 * 1000 * 24)).toLocaleDateString().replace(/[/]+/g, '-')
        },
        getInfo() {
            this.$axios.get(
                '/zhichanzhuanlishenqing/part1'
            ).then(res => {
                this.oneInfo = res.data
                this.init()
            })
            this.$axios.get(
                '/zhichanzhuanlishenqing/part2'
            ).then(res => {
                this.twoInfo = res.data
                this.towInit()
            })
            this.$axios.get(
                '/zhichanzhuanlishenqing/part3'
            ).then(res => {
                this.threeInfo = res.data
            })
        },
        init() {
            const titleSize = document.body.offsetWidth / 100
            // console.log(titleSize)
            // 基于准备好的dom，初始化echarts实例
            var xiangmuState = this.$echarts.init(document.getElementById('case-stage'));
            xiangmuState.setOption({
                title: {
                    show: false
                },
                tooltip: {},
                xAxis: {
                    type: 'category',
                    data: ['撰写阶段', '新建案件', '客户阶段', '预审阶段', '质检阶段', '案件完结'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#004880'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83,
                        interval: 0
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#34475D'
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        data: [this.oneInfo.zhuanxiejieduan, this.oneInfo.xinjiananjian, this.oneInfo.kehujieduan, this.oneInfo.yushenjieduan, this.oneInfo.zhijianjieduan, this.oneInfo.anjianwanjie],
                        barWidth: '15%',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 0, color: '#09D7FD' },
                                        { offset: 1, color: '#3A88FC' }
                                    ]
                                )
                            }
                        }
                    }
                ],
                grid: {
                    top: '10%',
                    bottom: '20%',
                    left: '10%',
                    right: '0'
                }
            });
        },
        towInit() {
            const titleSize = document.body.offsetWidth / 100
            var prequalification = this.$echarts.init(document.getElementById('prequalification-status'));
            prequalification.setOption({
                title: {
                    show: false
                },
                tooltip: {},
                xAxis: {
                    type: 'category',
                    data: ['转为普通', '预审没过需退款', '预审未过转为普通B3'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#004880'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83,
                        interval: 0
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#34475D'
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        data: [this.twoInfo.zhuanweiputong, this.twoInfo.yushenmeiguoxutuikuan, this.twoInfo.yushenweiguozhuanweiputongb3],
                        barWidth: '15%',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 0, color: '#09D7FD' },
                                        { offset: 1, color: '#3A88FC' }
                                    ]
                                )
                            }
                        }
                    }
                ],
                grid: {
                    top: '10%',
                    bottom: '20%',
                    left: '10%',
                    right: '0'
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.project-status {
    margin-top: 1rem;
    width: 26.31vw;

    .project-status-title {
        display: flex;
        align-items: center;
        justify-content: center;

        &-text {
            font-size: 1.125rem;
            color: #66D9FF;
            margin: 0 0.3125rem;
        }

        &-line {
            height: 0.125rem;
            width: 2.68rem;
            background-color: #66D9FF;
        }
    }

    .client-zhu-canvas {
        width: 100%;
        height: 14.5rem;
    }
}

.ribao {
    margin-top: 1.5rem;
    width: 26.31vw;

    .ribao-item {
        width: 100%;
        height: 6.6875rem;
        background-image: url(../../assets/shenji/ribaobg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;

        .ribao-image {
            width: 4.625rem;
            height: 4.3125rem;
            margin-left: 2.9375rem;
        }

        .ribao-right {
            margin-left: 5rem;
            color: $fontWhiteColor;

            .ribao-time {
                font-size: 1rem;
                font-weight: 500;
            }

            .ribao-number {
                font-weight: 500;
                font-size: 1.25rem;
                display: flex;
                align-items: center;

                .ribao-text {
                    font-size: 0.875rem;
                }
            }

            .ribao-little {
                font-size: 0.875rem;
            }
        }
    }

    .ribao-zuo {
        background-image: url(../../assets/shenji/zuoribao.png) !important;
    }
}</style>