<template>
    <div>
        <div class="small-title">
            商标业务数量统计
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">商标业务数量</div>
                <div class="project-status-title-line"></div>
            </div>
            <div class="project-status-canvas" id="vocational-work"></div>
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">商标非注册</div>
                <div class="project-status-title-line"></div>
            </div>
            <div class="project-status-canvas" id="no-logon"></div>
        </div>
        <div class="small-title margingTop">
            作品版权业务数量统计
        </div>
        <div class="work-copyright-canvas" id="work-copyright">
            <div class="work-copyright-bg"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'knowledgeLeft',
    data() {
        return {
            trademarkInfo: {},
            workInfo: {}
        }
    },
    mounted() {
        this.getTrademarkInfo()
        this.getWorkInfo()
    },
    methods: {
        setLeftData(item, type) {
            if (type == 45) {
                this.trademarkInfo = item
                this.trademarkInto()
            }
            if (type == 46) {
                this.twoInfo = item
                this.twoInit()
            }
            if (type == 47) {
                this.workInfo = item
                this.workInfoInit()
            }
        },
        getTrademarkInfo() {
            this.$axios.get(
                '/zhichanshangbiaobanquan/part1'
            ).then(res => {
                this.trademarkInfo = res.data
                this.trademarkInto()
            })
            this.$axios.get(
                '/zhichanshangbiaobanquan/part2'
            ).then(res => {
                this.twoInfo = res.data
                this.twoInit()
            })

        },
        getWorkInfo() {
            this.$axios.get(
                '/zhichanshangbiaobanquan/part3'
            ).then(res => {
                this.workInfo = res.data
                this.workInfoInit()
            })
        },
        trademarkInto() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var varEchart = this.$echarts.init(document.getElementById('vocational-work'));
            varEchart.setOption({
                grid: {
                    top: '15%',
                    left: '4%',
                    right: '5%',
                    bottom: '6%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#26367A'
                        }
                    },
                    splitArea: {
                        show: false,

                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#E5F1FB',
                            fontSize: [titleSize * 0.83]
                        },
                        interval: 0,
                    },


                    data: ['商标注册', '商标非注册', '商标评审', '商标涉外', '作品版权', '商标其他'],

                }],

                yAxis: [{
                    type: 'value',
                    name: '',
                    splitLine: {
                        show: true,
                        interval: '0',
                        lineStyle: {
                            color: ['#34475D'],
                            type: 'solid',
                            width: 1
                        }
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        // margin: 10,
                        textStyle: {
                            fontSize: titleSize * 0.83,
                            color: '#E5F1FB'
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                series: [{
                    name: '项目数量',
                    type: 'line',
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        color: "#00F5FF",
                    },

                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: 'rgba(0, 245, 225, 0.56)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(0, 245, 225, 0)'
                                }
                            ],
                            global: false // 缺省为 false
                        }
                    },
                    data: [this.trademarkInfo.shangbiaozhuce, this.trademarkInfo.shangbiaofeizhuce, this.trademarkInfo.shangbiaopingshen, this.trademarkInfo.shangbiaoshewai, this.trademarkInfo.zuopinbanquan, this.trademarkInfo.shangbiaoqita]
                }
                ]
            })

        },
        twoInit() {
            const titleSize = document.body.offsetWidth / 100
            var logonEchart = this.$echarts.init(document.getElementById('no-logon'));
            logonEchart.setOption({
                grid: {
                    top: '15%',
                    left: '4%',
                    right: '5%',
                    bottom: '6%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#26367A'
                        }
                    },
                    splitArea: {
                        show: false,

                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#E5F1FB',
                            fontSize: titleSize * 0.83
                        },
                        interval: 0,
                    },


                    data: ['补证业务', '宽展业务', '变更业务', '变更代理人', '转让业务'],

                }],

                yAxis: [{
                    type: 'value',
                    name: '',
                    splitLine: {
                        show: true,
                        interval: '0',
                        lineStyle: {
                            color: ['#34475D'],
                            type: 'solid',
                            width: 1
                        }
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        // margin: 10,
                        textStyle: {
                            fontSize: titleSize * 0.83,
                            color: '#E5F1FB'
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                series: [{
                    name: '项目数量',
                    type: 'line',
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        color: "#00F5FF",
                    },

                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: 'rgba(0, 245, 225, 0.56)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(0, 245, 225, 0)'
                                }
                            ],
                            global: false // 缺省为 false
                        }
                    },
                    data: [this.twoInfo.buzhengyewu, this.twoInfo.kuanzhanyewu, this.twoInfo.biangengyewu, this.twoInfo.biangengdailiren, this.twoInfo.zhuanrangyewu]
                }
                ]
            })
        },
        workInfoInit() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var varEchart = this.$echarts.init(document.getElementById('work-copyright'));
            varEchart.setOption({
                series: [
                    {
                        avoidLabelOverlap: false,
                        type: 'pie',
                        roseType: 'area', // 玫瑰图
                        center: ['45%', '50%'],
                        radius: ['20%', '50%'],
                        label: {
                            normal: {
                                formatter: '{c}\n————\n{b}',
                                textStyle: {
                                    fontSize: titleSize * 0.83
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                length: 10,
                                length2: 30
                            }
                        },
                        data: [{
                            name: '变更版权',
                            value: Number(this.workInfo.biangeng),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#62f4a8',

                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#62f4a8'
                                    }, {
                                        offset: 1,
                                        color: '#31e3ff'
                                    }])
                                }
                            }
                        }, {
                            name: '转让版权',
                            value: Number(this.workInfo.zhuanrang),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#3a88fc'
                                    }])
                                }
                            }
                        }, {
                            name: '申请版权',
                            value: Number(this.workInfo.shenqing),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#69bbff'
                                    }])
                                }
                            }
                        }]
                    }
                ]
            })

        }
    }
}
</script>

<style scoped lang="scss">
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.margingTop {
    margin-top: 2rem;
}

.project-status {
    margin-top: 1rem;
    width: 26.31vw;

    .project-status-title {
        display: flex;
        align-items: center;
        justify-content: center;

        &-text {
            font-size: 1.125rem;
            color: #66D9FF;
            margin: 0 0.3125rem;
        }

        &-line {
            height: 0.125rem;
            width: 2.68rem;
            background-color: #66D9FF;
        }
    }

    .project-status-canvas {
        width: 100%;
        height: 12.5rem;
    }
}

.work-copyright-canvas {
    width: 26.31vw;
    height: 14.75rem;
    position: relative;
    background-image: url(../../assets/highTech/leftBg.png);
    background-size: 30% 60%;
    background-repeat: no-repeat;
    background-position: 42.5% 48%;

}
</style>