<template>
    <div class="among">
        <div class="among-item">
            <div class="among-item-text paddingTop">系统内客户</div>
            <div class="among-item-text">总量</div>
            <div class="among-item-number">{{ xitongneikehuzongliang }}</div>
        </div>
        <div class="among-item">
            <div class="among-item-text paddingTop">科技服务归口</div>
            <div class="among-item-text">案件数</div>
            <div class="among-item-number">{{ kejifuwuguikouanjianshu }}</div>
        </div>
        <div class="among-item">
            <div class="among-item-text paddingTop">知识产权归口</div>
            <div class="among-item-text">案件数</div>
            <div class="among-item-number">{{zhishichanquanguikouanjianshu}}</div>
        </div>
        <div class="among-item">
            <div class="among-item-text paddingTop">集团</div>
            <div class="among-item-text">总人数</div>
            <div class="among-item-number">{{jituanzongrenshu}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'amongContent',
    data() {
        return {
            jituanzongrenshu:0,
            kejifuwuguikouanjianshu:0,
            xitongneikehuzongliang:0,
            zhishichanquanguikouanjianshu:0
        }
    },
    mounted() {
        this.getAmongInfo()
    },
    methods: {
        getAmongInfo() {
            this.$axios.get(
                '/zhongjianshujv'
            ).then(res => {
                this.jituanzongrenshu = res.data.jituanzongrenshu
                this.kejifuwuguikouanjianshu = res.data.kejifuwuguikouanjianshu
                this.xitongneikehuzongliang = res.data.xitongneikehuzongliang
                this.zhishichanquanguikouanjianshu = res.data.zhishichanquanguikouanjianshu
            })
        }
    }
}
</script>

<style scoped lang="scss">
.among {
    display: flex;
    align-items: center;

    .among-item {
        width: 8.8125rem;
        height: 7.3125rem;
        background-image: url(../assets/centerBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;

        .among-item-text {
            font-size: 1.25rem;
            color: #e5f1fb;
            font-family: "YouSheBiaoTiHei";

        }

        .paddingTop {
            padding-top: 0.5rem;
        }

        .among-item-number {
            margin-top: 0.5rem;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 1.875rem;
            font-family: 'RobotoMedium';
        }
    }
}</style>