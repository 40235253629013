<template>
    <div>
        <div class="small-title">
            科技服务部项目信息
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">项目计数</div>
                <div class="project-status-title-line"></div>
            </div>
            <div class="client-zhu-canvas" id="item-count"></div>
        </div>
        <div class="project-status" style="margin-top: 0;">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">双软服务类型</div>
                <div class="project-status-title-line"></div>
            </div>
            <div class="client-zhu-canvas" id="service-status"></div>
        </div>
        <div class="small-title">
            双软流程实时图表
        </div>
        <div class="tree-canvas" id="tree-info">

        </div>
    </div>
</template>

<script>
export default {
    name: 'technologyLeft',
    data() {
        return {
            oneInfo:{},
            twoInfo:{},
            threeInfo:{}
        }
    },
    mounted() {
        this.getInfo()
        this.getTreeInfo()
    },
    methods: {
        setLeftData(type,item){
            if(type == 50){
                this.oneInfo = item
                this.init()
            }
            if(type == 51){
                this.twoInfo = item
                this.twoInit()
            }
            if(type == 52){
                this.threeInfo = item
                this.treeInit()
            }
        },
        getInfo() {
            this.$axios.get(
                '/kejifuwuyewu/part1'
            ).then(res => {
                this.oneInfo = res.data
                this.init()
            })
            this.$axios.get(
                '/kejifuwuyewu/part2'
            ).then(res => {
                this.twoInfo = res.data
                this.twoInit()
            })
        },
        getTreeInfo() {
            this.$axios.get(
                '/kejifuwuyewu/part3'
            ).then(res => {
                this.threeInfo = res.data
                this.treeInit()
            })
        },
        treeInit() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var varEchart = this.$echarts.init(document.getElementById('tree-info'));
            varEchart.setOption({
                series: [
                    {
                        avoidLabelOverlap: false,
                        type: 'pie',
                        roseType: 'area', // 玫瑰图
                        center: ['45%', '50%'],
                        radius: ['20%', '50%'],
                        label: {
                            normal: {
                                formatter: '{c}%\n————\n{b}',
                                textStyle: {
                                    fontSize: titleSize * 0.83
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                length: 10,
                                length2: 30
                            }
                        },
                        data: [{
                            name: '创建项目',
                            value: Number(this.threeInfo.chuangjianxiangmubaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#62f4a8',

                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#62f4a8'
                                    }, {
                                        offset: 1,
                                        color: '#31e3ff'
                                    }])
                                }
                            }
                        }, {
                            name: '软件审核',
                            value: Number(this.threeInfo.ruanjianshenhebaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#ffb01f'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                        offset: 0,
                                        color: '#ffb01f'
                                    }, {
                                        offset: 1,
                                        color: '#5ce7ff'
                                    }])
                                }
                            }
                        }, {
                            name: '软件登记&评测',
                            value: Number(this.threeInfo.ruanjiandengjipingshibaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#3a88fc'
                                    }])
                                }
                            }
                        },{
                            name: '软件归档',
                            value: Number(this.threeInfo.ruanjianguidangbaifenbi),
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#0ad6fd'
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                        offset: 0,
                                        color: '#0ad6fd'
                                    }, {
                                        offset: 1,
                                        color: '#69bbff'
                                    }])
                                }
                            }
                        }]
                    }
                ]
            })
        },
        init() {
            const titleSize = document.body.offsetWidth / 100
            // console.log(titleSize)
            // 基于准备好的dom，初始化echarts实例
            var xiangmuState = this.$echarts.init(document.getElementById('item-count'));
            xiangmuState.setOption({
                title: {
                    show: false
                },
                tooltip: {},
                xAxis: {
                    type: 'category',
                    data: ['进行中', '已完成', '已暂停', '已作废'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#004880'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#34475D'
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        data: [this.oneInfo.jinxingzhong, this.oneInfo.yiwancheng, this.oneInfo.yizanting, this.oneInfo.yizuofei],
                        barWidth: '15%',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 0, color: '#09D7FD' },
                                        { offset: 1, color: '#3A88FC' }
                                    ]
                                )
                            }
                        }
                    }
                ],
                grid: {
                    top: '10%',
                    bottom: '20%',
                    left: '10%',
                    right: '0'
                }
            });
            // console.log(titleSize)
            // 基于准备好的dom，初始化echarts实例
            
        },
        twoInit(){
            const titleSize = document.body.offsetWidth / 100
            var prequalification = this.$echarts.init(document.getElementById('service-status'));
            prequalification.setOption({
                title: {
                    show: false
                },
                tooltip: {},
                xAxis: {
                    type: 'category',
                    data: ['部分内容', '只撰写', '全流程'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#004880'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83,
                        interval: 0
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#34475D'
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        data: [this.twoInfo.bufenneirong, this.twoInfo.zhizhuanxie, this.twoInfo.quanliucheng],
                        barWidth: '15%',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 0, color: '#09D7FD' },
                                        { offset: 1, color: '#3A88FC' }
                                    ]
                                )
                            }
                        }
                    }
                ],
                grid: {
                    top: '10%',
                    bottom: '20%',
                    left: '10%',
                    right: '0'
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.project-status {
    margin-top: 1rem;
    width: 26.31vw;

    .project-status-title {
        display: flex;
        align-items: center;
        justify-content: center;

        &-text {
            font-size: 1.125rem;
            color: #66D9FF;
            margin: 0 0.3125rem;
        }

        &-line {
            height: 0.125rem;
            width: 2.68rem;
            background-color: #66D9FF;
        }
    }

    .client-zhu-canvas {
        width: 100%;
        height: 14.5rem;
    }
}

.tree-canvas {
    width: 26.31vw;
    height: 15rem;
}
</style>