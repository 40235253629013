<template>
    <div class="all-body">
        <leftContent ref="knowleLeftRef"></leftContent>
        <amongContent ref="amongContentRef"></amongContent>
        <rightContent ref="knowleRightRef"></rightContent>
    </div>
</template>

<script>
import leftContent from '../components/knowledge/leftContent.vue';
import rightContent from '../components/knowledge/rightContent.vue';
import amongContent from '../components/amongContent.vue';
export default {
    name: "knowledge",
    data() {
        return {

        }
    },
    components: {
        leftContent,
        rightContent,
        amongContent
    },
    methods:{
        fenData(type,item){
            if(type == 28){
                this.$refs.amongContentRef.xitongneikehuzongliang = item.xitongneikehuzongliang
                this.$refs.amongContentRef.kejifuwuguikouanjianshu = item.kejifuwuguikouanjianshu
                this.$refs.amongContentRef.zhishichanquanguikouanjianshu = item.zhishichanquanguikouanjianshu
                this.$refs.amongContentRef.jituanzongrenshu = item.jituanzongrenshu
            }
            if (type < 48 && type >44 ) {
                this.$refs.knowleLeftRef.setLeftData(type, item)
            }
            if (type > 47 ) {
                this.$refs.knowleRightRef.setRightData(type, item)
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.all-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
</style>