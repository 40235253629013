<template>
    <div class="all-body">
        <leftContent ref="manLeft"></leftContent>
        <amongContent ref="amongContentRef"></amongContent>
        <rightContent ref="manRight"></rightContent>
    </div>
</template>

<script>
import leftContent from '../components/manpower/leftContent.vue';
import rightContent from '../components/manpower/rightContent.vue';
import amongContent from '../components/amongContent.vue';
export default {
    name: "manpower",
    data() {
        return {

        }
    },
    components: {
        leftContent,
        rightContent,
        amongContent
    },
    methods:{
        fenData(type,item){
            if(type == 24 || type == 26){
                this.$refs.manLeft.setManLeftData(type,item)
            }
            if(type == 25 || type == 27){
                this.$refs.manRight.setManRightData(type,item)
            }
            if(type == 28){
                this.$refs.amongContentRef.xitongneikehuzongliang = item.xitongneikehuzongliang
                this.$refs.amongContentRef.kejifuwuguikouanjianshu = item.kejifuwuguikouanjianshu
                this.$refs.amongContentRef.zhishichanquanguikouanjianshu = item.zhishichanquanguikouanjianshu
                this.$refs.amongContentRef.jituanzongrenshu = item.jituanzongrenshu
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.all-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
</style>