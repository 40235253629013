<template>
    <div>
        <div class="small-title">
            科技服务部整体进度
        </div>
        <div class="rate-progress-canvas" id="rate-progress">
        </div>
        <div class="small-title">
            项目数据汇总
        </div>
        <div class="audit-info">
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ fiveInfo.sopxiangmuzongshu }}</div>
                <div class="audit-info-item-text">SOP项目总数</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ fiveInfo.kejifuwubuxiangmuzongshu }}</div>
                <div class="audit-info-item-text">科技服务部项目总数</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ fiveInfo.chuangjianxiangmu }}</div>
                <div class="audit-info-item-text"> 创建项目</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ fiveInfo.shuangruandanqianxiangmuzongshu }}</div>
                <div class="audit-info-item-text">双软单签项目总数</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'technologyRight',
    data() {
        return {
            fourInfo:{},
            fiveInfo:{}
        }
    },
    mounted() {
        this.getRateInfo()
    },
    methods: {
        setRightData(type,item){
            if(type == 53){
                this.fourInfo = item
                this.rateInte()
            }
            if(type == 54){
                this.fiveInfo = item
            }
        },
        getRateInfo() {
            this.$axios.get(
                '/kejifuwuyewu/part4'
            ).then(res => {
                this.fourInfo = res.data
                this.rateInte()
            })
            this.$axios.get(
                '/kejifuwuyewu/part5'
            ).then(res => {
                this.fiveInfo = res.data
            })
        },
        rateInte() {
            const titleSize = document.body.offsetWidth / 100
            const myRateEchart = this.$echarts.init(document.getElementById('rate-progress'));
            myRateEchart.setOption({
                grid: {
                    left: '3%',
                    right: '10%',
                    bottom: '10%',
                    top: "0%",
                    containLabel: true
                },
                xAxis: [{
                    type: "value",
                    splitNumber: 5, //划分等分
                    axisLabel: {
                        show: false,
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: { //x轴线样式
                        lineStyle: {
                            color: '#34475D',
                        },

                    },

                    splitLine: { //设置网格颜色
                        show: true,
                        lineStyle: {
                            color: '#34475D',
                        },
                    }
                }],
                yAxis: [{
                    //左边的纵轴
                    type: "category",
                    inverse: true, //是否将数据反转,所给的数据为正常数据
                    axisLabel: {
                        show: true,
                        textStyle: {
                            fontSize: titleSize*0.83,
                            color: '#ffffff',
                        },
                    },
                    axisLine: { //y轴线样式
                        lineStyle: {
                            color: '#34475D',
                        }
                    },
                    // 纵坐标数据
                    data: ["暂停", "撰写中", "提交", "撰写已完成", "已通过", "未通过", "停止"], //左边纵坐标值
                    yAxisIndex: 0,
                    // 横坐标 分割线等取消显示
                    axisTick: {
                        show: false
                    }

                },
                { //右边的纵轴
                    type: "category",
                    inverse: true,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: true,
                        inside: false,
                        verticalAlign: "left",
                        // 位置 上右下左
                        padding: [-7, 0, 2, 0],
                        textStyle: {
                            fontSize: titleSize*0.83,
                            color: '#FFFFFF'
                        }
                    },
                    // 统计的总量 用纵坐标模拟
                    data: [this.fourInfo.zantingbaifenbi+'%', this.fourInfo.zhuanxiezhongbaifenbi+'%', this.fourInfo.tijiaobaifenbi+'%', this.fourInfo.zhuanxieyiwanchengbaifenbi+'%', this.fourInfo.yitongguobaifenbi+'%', this.fourInfo.weitongguobaifenbi+'%', this.fourInfo.tingzhibaifenbi+'%'], //右边纵坐标的值
                },

                ],
                series: [{
                    //虚线柱形
                    show: true,
                    type: 'pictorialBar',
                    symbol: 'fixed',
                    symbolSize: [8, 11], //黑色间隔的宽，高
                    symbolMargin: 1, //黑色间隔的间距
                    left: 10,
                    symbolRepeat: 'repeat',
                    barWidth: 3, // 统计条宽度
                    boundaryGap: true, //设置距离左边位置
                    itemStyle: {
                        normal: {
                            color: '#00E9F7'
                        },
                    },

                    z: 3, //z 值小的图形会被z值大的图形覆盖。
                    data: [this.fourInfo.zantingbaifenbi, this.fourInfo.zhuanxiezhongbaifenbi, this.fourInfo.tijiaobaifenbi, this.fourInfo.zhuanxieyiwanchengbaifenbi, this.fourInfo.yitongguobaifenbi, this.fourInfo.weitongguobaifenbi, this.fourInfo.tingzhibaifenbi],
                },

                { //总进度底色
                    show: true,
                    type: 'pictorialBar',
                    symbol: 'fixed',
                    symbolSize: [8, 11],
                    symbolMargin: 1,
                    symbolRepeat: 'repeat',
                    barGap: '-100%',
                    barWidth: 3, // 统计条宽度
                    itemStyle: {
                        normal: {
                            color: '#06475D',
                        },
                    },

                    z: 2,
                    data: [100, 100, 100, 100, 100, 100, 100],
                },
                ]
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.rate-progress-canvas {
    width: 26.31vw;
    height: 38.89vh;
}
.audit-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 26.31vw;

    .audit-info-item {
        width: 11.25rem;
        height: 8.0625rem;
        background-image: url(../../assets/shenji/yishen.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        margin-top: 3rem;
        .audit-info-item-number {
            padding-top: 0.75rem;
            font-weight: 500;
            font-size: 1.25rem;
            color: #03E5F1;
            font-family: 'RobotoMedium';
        }

        .audit-info-item-text {
            margin-top: 1rem;
            font-weight: 500;
            font-size: 1rem;
            color: #E5F1FB;
        }
    }
}
</style>