<template>
  <div>
    <!-- 背景 -->
    <div class="videoContainer">
      <video class="fullscreenVideo" id="bgVid" playsinline="" poster="/src/assets/bigBg.png" autoplay="" muted=""
        loop="">
        <source src="../assets/bgMp.mp4" type="video/mp4">
      </video>
    </div>
    <!-- 头部文字 -->
    <div class="hearder-div">
      <div class="header-container">
        <div class="header-content">{{ titleText }}</div>
      </div>
    </div>
    <!-- 内容框架 -->
    <div class="content" @click="clickNext">
      <!-- 审计 -->
      <shenJi ref="shenJi" v-if="current == 1"></shenJi>
      <!-- 高新技术 -->
      <highTech ref="highTech" v-if="current == 2"></highTech>
      <!-- 知识商标 -->
      <knowledge ref="knowledge" v-if="current == 3"></knowledge>
      <!-- 人力资源 -->
      <manpower ref="manpower" v-if="current == 4"></manpower>
      <!-- 客户营销 -->
      <customer ref="customer" v-if="current == 5"></customer>
      <!-- 知产专利 -->
      <patent ref="patent" v-if="current == 6"></patent>
      <!-- 科技业务 -->
      <technology ref="technology" v-if="current == 7"></technology>
    </div>
  </div>
</template>

<script>
import shenJi from '../components/shenJi.vue';
import highTech from './highTech.vue';
import knowledge from "./knowledge.vue"
import manpower from './manpower.vue';
import customer from './customer.vue'
import patent from './patent.vue';
import technology from './technology.vue';
export default {
  name: 'Home',
  components: {
    shenJi,
    highTech,
    knowledge,
    manpower,
    customer,
    patent,
    technology
  },
  data() {
    return {
      titleText: "天皓集团科技服务高新数据可视化平台",
      current: 1,
      websocketData: {
        websocketLockReconnect: false,
        // websocketUrl: "ws://192.168.1.118:10001",
        websocketUrl: 'wss://ipaas.jishanyun.com:10001',
        websocketSingleton: null,
        websocketTiktok: null
      }

    }
  },
  created() {
    window.test = (info) => {
      if (info == 37) {
        // 左
        this.current -= 1
        if (this.current < 1) {
          this.current = 7
        }
      }
      if (info == 38) {
        // 上
        this.current += 1
        if (this.current > 7) {
          this.current = 1
        }
      }
      if (info == 39) {
        // 右
        this.current += 1
        if (this.current > 7) {
          this.current = 1
        }
      }
      if (info == 40) {
        // 下
        this.current -= 1
        if (this.current < 1) {
          this.current = 7
        }
      }
    };
    setTimeout(() => {
      this.websocketCreate()
    }, 5000)
  },
  // computed: {
  //   remote() {
  //     return this.$remote
  //   }
  // },
  watch: {
    current(newVal, oldVal) {
      if (newVal == 1) {
        this.titleText = '天皓集团科技服务审计数据可视化平台'
      }
      if (newVal == 2) {
        this.titleText = '天皓集团科技服务高新数据可视化平台'
      }
      if (newVal == 3) {
        this.titleText = '天皓集团知产商标版权数据可视化平台'
      }
      if (newVal == 4) {
        this.titleText = '天皓集团人力资源管理数据可视化平台'
      }
      if (newVal == 5) {
        this.titleText = '天皓集团客户营销管理数据可视化平台'
      }
      if (newVal == 6) {
        this.titleText = '天皓集团知产专利申请数据可视化平台'
      }
      if (newVal == 7) {
        this.titleText = '天皓集团科技服务业务数据可视化平台'
      }
    }
  },
  methods: {
    clickNext() {
      // return
      this.current += 1
      if (this.current > 7) {
        this.current = 1
      }
    },
    websocketCreate() {
      try {
        this.websocketData.websocketSingleton = new WebSocket(this.websocketData.websocketUrl);
        this.websocketInitialize();
      } catch (e) {
        this.websocketReconnect(this.websocketData.websocketUrl);
      }
    },
    websocketInitialize() {
      this.websocketData.websocketSingleton.onerror = (event) => {
        // console.log("socket连接失败");
        // console.log(event);
        // 重连
        this.websocketReconnect(this.websocketData.websocketUrl);
      }

      // 连接成功建立的回调方法
      this.websocketData.websocketSingleton.onopen = (event) => {
        // console.log("socket连接已打开");
        // 心跳检测重置
        this.websocketHeartBeat();
      }

      // 接收到消息的回调方法
      this.websocketData.websocketSingleton.onmessage = (event) => {
        var data = eval("(" + event.data + ")");
        // console.log(data)
        if (data.type != 0) {
          var type = data.type;
          var item = data.data
          // webMessageInfo.propsType = type
          // console.log(type,item)
          if ((type < 24 || type == 28 ) && this.current == 1) {
            // 审计的信息
            this.$refs.shenJi.fenData(type, item)
          }
          // 人力
          if(( (type>23 && type < 28) || type == 28) && this.current == 4){
            this.$refs.manpower.fenData(type,item)
          }

          // 高新
          if(  type>27 && type<34 && this.current == 2 ){
            this.$refs.highTech.fenData(type,item)
          }

          // 客户营销
          if ( ((type > 33 && type <39 ) || type == 28 ) && this.current == 5 ){
            this.$refs.customer.fenData(type,item)
          }

          // 知产专利
          if( ((type>38 && type<45) || type == 28) && this.current == 6  ){
            this.$refs.patent.fenData(type,item)
          }
          // 知识商标
          if( this.current == 3 && ( (type>44 && type <50) || type == 28 ) ){
            this.$refs.knowledge.fenData(type,item)
          }
          // 科技业务
          if( this.current == 7 && ( type > 49 || type == 28 ) ){
            this.$refs.technology.fenData(type,item)
          }
        }

      };

      // 连接关闭的回调方法
      this.websocketData.websocketSingleton.onclose = (event) => {
        // console.log("socket连接已关闭");
        // console.log('onclose');
        // console.log(event);
        // 重连
        this.websocketReconnect(this.websocketData.websocketUrl);
      };

      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接
      window.onbeforeunload = function () {
        this.websocketData.websocketSingleton.close();
      };

    },
    // websocket重连
    websocketReconnect(url) {
      if (this.websocketData.websocketLockReconnect) {
        return;
      }
      this.websocketData.websocketLockReconnect = true;
      // 没连接上会一直重连，设置延迟避免请求过多
      this.websocketData.websocketTiktok && clearTimeout(this.websocketData.websocketTiktok);
      this.websocketData.websocketTiktok = setTimeout(() => {
        this.websocketCreate(url);
        this.websocketData.websocketLockReconnect = false;
      }, 5000);
    },
    // websocket心跳
    websocketHeartBeat() {
      // 每15秒 发送心跳 空字符串
      setInterval(() => {
        this.websocketData.websocketSingleton.send('');
        // console.log('heart beat');
      }, 15000)
    }
  }
}
</script>

<style lang="scss" scoped>
.hearder-div {
  height: 7.03vh;
  width: 100%;
}

.videoContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -100;
}

.videoContainer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  background: rgba(25, 29, 34, .65);
  background-size: cover;
}

.fullscreenVideo {
  width: 100%;
  height: 100%;
  object-fit: fill
}

.header-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  line-height: 7.03vh;
  text-align: center;
}

.header-content {
  font-size: 2rem;
  color: #FFFFFF;
  font-family: 'YouSheBiaoTiHei';
  background: linear-gradient(0deg, #51BBFF 0%, #FFFFFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content {
  padding: 1.29vh 1.3vw 0;
}
</style>
