<template>
    <div>
        <div class="small-title">
            客户信息预览
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">跟进阶段柱状图</div>
                <div class="project-status-title-line"></div>
            </div>
            <div class="client-zhu-canvas" id="client-stage"></div>
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">客户所属状态百分比</div>
                <div class="project-status-title-line"></div>
            </div>
        </div>
        <div class="service-type">
            <div class="service-type-item">
                <div class="service-type-item-canvas zong-canvas" id="zong-gonghai">
                </div>
                <div>
                    <div class="service-type-item-line"></div>
                    <div class="service-type-item-title">总公海</div>
                    <div class="service-type-item-number">
                        <div class="number-bai">{{ yuanInfo.zonggonghaibaifenbi }}%</div>
                        <div class="number-all">{{ yuanInfo.zonggonghai }}</div>
                    </div>
                </div>
            </div>
            <div class="service-type-item marginTop">
                <div class="service-type-item-canvas fei-canvas" id="fei-gonghai">
                </div>
                <div>
                    <div class="service-type-item-line"></div>
                    <div class="service-type-item-title">非公海</div>
                    <div class="service-type-item-number">
                        <div class="number-bai" style="color: #65A4FF;">{{ yuanInfo.feigonghaibaifenbi }}%</div>
                        <div class="number-all">{{yuanInfo.feigonghai}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="project-status">
            <div class="project-status-title">
                <div class="project-status-title-line"></div>
                <div class="project-status-title-text">客户累计总额</div>
                <div class="project-status-title-line"></div>
            </div>
        </div>
        <div class="recruitment-status">
            <div class="status-item">
                <div class="item-title">{{yihuikuanzonge}}</div>
                <div class="item-type">已回款</div>
                <div class="item-text">累计总额</div>
            </div>
            <div class="status-item">
                <div class="item-title">{{ daihuikuanzonge }}</div>
                <div class="item-type">待回款</div>
                <div class="item-text">累计总额</div>
            </div>
            <div class="status-item">
                <div class="item-title">{{ kehuzongshu }}</div>
                <div class="item-type">客户总数</div>
                <div class="item-text">累计总额</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'customer',
    data() {
        return {
            zonggonghai:90.95,
            feigonghai:9.05,
            kehuzongshu:14212,
            daihuikuanzonge:891214,
            yihuikuanzonge:821411,
            zhuDataInfo:{},
            yuanInfo:{}
        }
    },
    mounted() {
        this.getClientZhuInfo()
    },
    methods: {
        setLeftData(type,item){
            if(type == 34){
                this.zhuDataInfo.shangmenbaifang = item.shangmenbaifang
                this.zhuDataInfo.fanganxieshang = item.fanganxieshang
                this.zhuDataInfo.yixiangyaoyue = item.yixiangyaoyue
                this.zhuDataInfo.xinruku = item.xinruku
                this.zhuDataInfo.yichengjiao = item.yichengjiao
                this.getClientZhuInit()
            }
            if(type == 35){
                this.yuanInfo.feigonghai = item.yuanInfo
                this.yuanInfo.zonggonghai = item.zonggonghai
                this.yuanInfo.feigonghaibaifenbi = item.feigonghaibaifenbi
                this.yuanInfo.zonggonghaibaifenbi = item.zonggonghaibaifenbi
                this.initYuan()
            }
            if(type == 36){
                this.yihuikuanzonge = item.yihuikuanzonge
                this.kehuzongshu = item.kehuzongshu
                this.daihuikuanzonge = item.daihuikuanzonge
            }
        },
        getClientZhuInfo() {
            this.$axios.get(
                '/kehuyingxiaoguanli/part1'
            ).then(res => {
                this.zhuDataInfo = res.data
                this.getClientZhuInit()
            })
            this.$axios.get(
                '/kehuyingxiaoguanli/part2'
            ).then(res => {
                this.yuanInfo = res.data
                this.initYuan()
            })
            this.$axios.get(
                '/kehuyingxiaoguanli/part3'
            ).then(res => {
                this.yihuikuanzonge = res.data.yihuikuanzonge
                this.kehuzongshu = res.data.kehuzongshu
                this.daihuikuanzonge = res.data.daihuikuanzonge
            })
            
        },
        getClientZhuInit() {
            const titleSize = document.body.offsetWidth / 100
            // console.log(titleSize)
            // 基于准备好的dom，初始化echarts实例
            var xiangmuState = this.$echarts.init(document.getElementById('client-stage'));
            xiangmuState.setOption({
                title: {
                    show: false
                },
                tooltip: {},
                xAxis: {
                    type: 'category',
                    data: ['上门拜访', '方案协商', '意向邀约', '新入库', '已成交'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#004880'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#34475D'
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#34475D'
                        }
                    },
                    axisLabel: {
                        color: '#e5f1fb',
                        fontSize: titleSize * 0.83
                    },
                },
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        data: [this.zhuDataInfo.shangmenbaifang, this.zhuDataInfo.fanganxieshang, this.zhuDataInfo.yixiangyaoyue, this.zhuDataInfo.xinruku, this.zhuDataInfo.yichengjiao],
                        barWidth: '15%',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 0, color: '#09D7FD' },
                                        { offset: 1, color: '#3A88FC' }
                                    ]
                                )
                            }
                        }
                    }
                ],
                grid: {
                    top: '10%',
                    bottom: '20%',
                    left: '10%',
                    right: '0'
                }
            });
        },
        initYuan(){
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var myChart = this.$echarts.init(document.getElementById('zong-gonghai'));
            // 绘制图表
            myChart.setOption({
                series: [//系列
                    {
                        name: '',
                        type: 'pie',//pie类型的图实现环形图
                        radius: ['52%', '60%'],//数组的话，表示内圆和外圆的半径大小，相对于宽高中较小的那一个。
                        center: ['50%', '50%'],//圆心坐标
                        avoidLabelOverlap: false,//是否启用防止标签重叠策略
                        label: {//每个数据的标签
                            show: false,//设置为true则显示第一个数据
                            position: 'center',//位置居中
                        },
                        color: ['#FBDB00', '#004880'],//系列的颜色
                        emphasis: {//高亮，即鼠标经过时的样式
                            scale: false//表示不放大item
                        },
                        labelLine: {
                            show: true
                        },
                        data: [
                            {
                                value: Number(this.yuanInfo.zonggonghaibaifenbi) ,
                                selected: false,
                                itemStyle: {
                                    normal: {
                                        borderRadius: 8,
                                        color: {
                                            type: 'linear',
                                            colorStops: [
                                                { offset: 0, color: '#22CD92' },
                                                { offset: 1, color: '#10EBDD' },
                                            ],
                                        },
                                    }
                                },
                                label: {
                                    borderRadius: 20,
                                }
                            },
                            {
                                value: 100 - Number(this.yuanInfo.zonggonghaibaifenbi),  //数据 按比例显示的
                                name: "",
                                itemStyle: {
                                    color: "transparent",
                                },
                            },
                        ]
                    }
                ]
            });
            var zhuanxiang = this.$echarts.init(document.getElementById('fei-gonghai'));
            // 绘制图表
            zhuanxiang.setOption({
                series: [//系列
                    {
                        name: '',
                        type: 'pie',//pie类型的图实现环形图
                        radius: ['52%', '60%'],//数组的话，表示内圆和外圆的半径大小，相对于宽高中较小的那一个。
                        center: ['50%', '50%'],//圆心坐标
                        avoidLabelOverlap: false,//是否启用防止标签重叠策略
                        label: {//每个数据的标签
                            show: false,//设置为true则显示第一个数据
                            position: 'center',//位置居中
                        },
                        color: ['#FBDB00', '#004880'],//系列的颜色
                        emphasis: {//高亮，即鼠标经过时的样式
                            scale: false//表示不放大item
                        },
                        labelLine: {
                            show: true
                        },
                        data: [
                            {
                                value: Number(this.yuanInfo.feigonghaibaifenbi),
                                selected: false,
                                itemStyle: {
                                    normal: {
                                        borderRadius: 8,
                                        color: {
                                            type: 'linear',
                                            colorStops: [
                                                { offset: 0, color: '#3A88FC' },
                                                { offset: 1, color: '#0BDCFF' },
                                            ],
                                        },
                                    }
                                },
                                label: {
                                    borderRadius: 20,
                                }
                            },
                            {
                                value: 100 - Number(this.yuanInfo.feigonghaibaifenbi),  //数据 按比例显示的
                                name: "",
                                itemStyle: {
                                    color: "transparent",
                                },
                            },
                        ]
                    }
                ]
            });
        }
    }

}
</script>

<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.project-status {
    margin-top: 1rem;
    width: 26.31vw;

    .project-status-title {
        display: flex;
        align-items: center;
        justify-content: center;

        &-text {
            font-size: 1.125rem;
            color: #66D9FF;
            margin: 0 0.3125rem;
        }

        &-line {
            height: 0.125rem;
            width: 2.68rem;
            background-color: #66D9FF;
        }
    }

    .client-zhu-canvas {
        width: 100%;
        height: 14.5rem;
    }
}
.service-type {
    padding: 1rem 3.5rem 0.5625rem 1.875rem;
    margin-top: 0.5rem;
    .service-type-item {
        display: flex;
        align-items: flex-start;

        .service-type-item-canvas {
            width: 5.875rem;
            height: 5.875rem;
        }

        .zong-canvas {
            background-image: url(../../assets/shenji/zonggonghai.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .fei-canvas {
            background-image: url(../../assets/shenji/feigonghai.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
        .service-type-item-line {
            height: 0.125rem;
            width: 17.375rem;
            background-color: #F5F9FF;
            opacity: 0.5;
            position: relative;
        }

        .service-type-item-line::after {
            content: "";
            height: 0.125rem;
            width: 1.2rem;
            background-color: #F5F9FF;
            transform: rotate(-45deg);
            position: absolute;
            top: 0.4rem;
            left: -0.99rem;
        }

        .service-type-item-title {
            margin-left: 1.8125rem;
            margin-top: 1rem;
            color: #BADDF1;
            font-size: 1rem;
        }

        .service-type-item-number {
            margin-left: 1.8125rem;
            margin-top: 0.2rem;
            display: flex;
            align-items: center;
            font-size: 1.25rem;
            font-family: 'RobotoMedium';
            color: $fontWhiteColor;
            .number-bai {
                color: #22CD92;
            }
            .number-all {
                margin-left: 1.5625rem;
            }
        }
    }

    .marginTop {
        margin-top: 1.1875rem;
    }
}
.recruitment-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;

    .status-item {
        width: 8.9375rem;
        height: 11.75rem;
        background-image: url(../../assets/highTech/typeBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        flex-direction: column;

        .item-title {
            padding-top: 1.5rem;
            font-size: 1.25rem;
            color: $fontWhiteColor;
            font-weight: bold;
        }

        .item-type {
            margin-top: 1rem;
            font-size: 1rem;
            color: #31E3FF;
        }

        .item-text {
            color: $fontWhiteColor;
            font-size: 1rem;
            margin-top: 1.5rem;
        }
    }
}
</style>