<template>
    <div>
        <div class="small-title">
            高新案件所属阶段环形图
        </div>
        <div class="service-type">
            <div class="service-type-item">
                <div class="service-type-item-canvas nianshen-canvas" id="nian-shen">
                </div>
                <div>
                    <div class="service-type-item-line"></div>
                    <div class="service-type-item-title">高新材料</div>
                    <div class="service-type-item-number">
                        <div class="number-bai">{{ gaoxincailiaobaifenbi }}%</div>
                        <div class="number-all">{{ gaoxincailiao }}</div>
                    </div>
                </div>
            </div>
            <div class="service-type-item marginTop">
                <div class="service-type-item-canvas zhuanxiang-canvas" id="zhuan-xiang">
                </div>
                <div>
                    <div class="service-type-item-line"></div>
                    <div class="service-type-item-title">领域确认单</div>
                    <div class="service-type-item-number">
                        <div class="number-bai" style="color: #65A4FF;">{{ lingyuquerendanbaifenbi }}%</div>
                        <div class="number-all">{{ lingyuquerendan }}</div>
                    </div>
                </div>
            </div>
            <div class="service-type-item marginTop">
                <div class="service-type-item-canvas fuzhu-canvas" id="fu-zhu-zhang">
                </div>
                <div>
                    <div class="service-type-item-line"></div>
                    <div class="service-type-item-title">RD包</div>
                    <div class="service-type-item-number">
                        <div class="number-bai" style="color: #03E5F1;">{{ rdbaobaifenbi }}%</div>
                        <div class="number-all">{{ rdbao }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="small-title">
            科小案件状态信息
        </div>
        <div class="audit-info">
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ infoData.kexiaoanjianzongshu }}</div>
                <div class="audit-info-item-text">科小案件总数</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ infoData.nianbaoanjianzongshu }}</div>
                <div class="audit-info-item-text">年报案件总数</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ infoData.kexiaogaoxinfudai }}</div>
                <div class="audit-info-item-text">科小高新附带</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ infoData.nianbaogaoxinfudai }}</div>
                <div class="audit-info-item-text">年报高新附带</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ infoData.kexiaoanjianjinxingzhong }}</div>
                <div class="audit-info-item-text">科小案件进行中</div>
            </div>
            <div class="audit-info-item">
                <div class="audit-info-item-number">{{ infoData.nianbaoanjianjinxingzhong }}</div>
                <div class="audit-info-item-text">年报案件进行中</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "shenJiRight",
    data() {
        return {
            gaoxincailiao:0,
            gaoxincailiaobaifenbi:0,
            lingyuquerendan:0,
            lingyuquerendanbaifenbi:0,
            rdbao:0,
            rdbaobaifenbi:0,
            infoData:{}
        }
    },
    mounted() {
        this.getFuwuType()
        this.getInfoData()
    },
    methods: {
        setRightData(type,item){
            if(type == 32){
                this.gaoxincailiao = item.gaoxincailiao
                this.lingyuquerendan = item.lingyuquerendan
                this.rdbao = item.rdbao
                this.gaoxincailiaobaifenbi = item.gaoxincailiaobaifenbi
                this.lingyuquerendanbaifenbi = item.lingyuquerendanbaifenbi
                this.rdbaobaifenbi = item.rdbaobaifenbi
                this.init()
            }
            if(type == 33){
                this.infoData.kexiaoanjianzongshu = item.kexiaoanjianzongshu
                this.infoData.nianbaoanjianzongshu = item.nianbaoanjianzongshu
                this.infoData.kexiaogaoxinfudai = item.kexiaogaoxinfudai
                this.infoData.nianbaogaoxinfudai = item.nianbaogaoxinfudai
                this.infoData.kexiaoanjianjinxingzhong = item.kexiaoanjianjinxingzhong
                this.infoData.nianbaoanjianjinxingzhong = item.nianbaoanjianjinxingzhong
            }
        },
        getInfoData() {
            this.$axios.get(
                '/kejifuwugaoxin/part5'
            ).then(res => {
                this.infoData = res.data
            })
        },
        getFuwuType() {
            this.$axios.get(
                '/kejifuwugaoxin/part4'
            ).then(res => {
                this.gaoxincailiao = res.data.gaoxincailiao
                this.gaoxincailiaobaifenbi = res.data.gaoxincailiaobaifenbi
                this.lingyuquerendan = res.data.lingyuquerendan
                this.lingyuquerendanbaifenbi = res.data.lingyuquerendanbaifenbi
                this.rdbao = res.data.rdbao
                this.rdbaobaifenbi = res.data.rdbaobaifenbi
                this.init()
            })
        },
        init() {
            const titleSize = document.body.offsetWidth / 100
            // 基于准备好的dom，初始化echarts实例
            var myChart = this.$echarts.init(document.getElementById('nian-shen'));
            // 绘制图表
            myChart.setOption({
                series: [//系列
                    {
                        name: '',
                        type: 'pie',//pie类型的图实现环形图
                        radius: ['52%', '60%'],//数组的话，表示内圆和外圆的半径大小，相对于宽高中较小的那一个。
                        center: ['50%', '50%'],//圆心坐标
                        avoidLabelOverlap: false,//是否启用防止标签重叠策略
                        label: {//每个数据的标签
                            show: false,//设置为true则显示第一个数据
                            position: 'center',//位置居中
                        },
                        color: ['#FBDB00', '#004880'],//系列的颜色
                        emphasis: {//高亮，即鼠标经过时的样式
                            scale: false//表示不放大item
                        },
                        labelLine: {
                            show: true
                        },
                        data: [
                            {
                                value: Number(this.gaoxincailiaobaifenbi) ,
                                selected: false,
                                itemStyle: {
                                    normal: {
                                        borderRadius: 8,
                                        color: {
                                            type: 'linear',
                                            colorStops: [
                                                { offset: 0, color: '#22CD92' },
                                                { offset: 1, color: '#10EBDD' },
                                            ],
                                        },
                                    }
                                },
                                label: {
                                    borderRadius: 20,
                                }
                            },
                            {
                                value: 100 - Number(this.gaoxincailiaobaifenbi),  //数据 按比例显示的
                                name: "",
                                itemStyle: {
                                    color: "transparent",
                                },
                            },
                        ]
                    }
                ]
            });
            var zhuanxiang = this.$echarts.init(document.getElementById('zhuan-xiang'));
            // 绘制图表
            zhuanxiang.setOption({
                series: [//系列
                    {
                        name: '',
                        type: 'pie',//pie类型的图实现环形图
                        radius: ['52%', '60%'],//数组的话，表示内圆和外圆的半径大小，相对于宽高中较小的那一个。
                        center: ['50%', '50%'],//圆心坐标
                        avoidLabelOverlap: false,//是否启用防止标签重叠策略
                        label: {//每个数据的标签
                            show: false,//设置为true则显示第一个数据
                            position: 'center',//位置居中
                        },
                        color: ['#FBDB00', '#004880'],//系列的颜色
                        emphasis: {//高亮，即鼠标经过时的样式
                            scale: false//表示不放大item
                        },
                        labelLine: {
                            show: true
                        },
                        data: [
                            {
                                value: Number(this.lingyuquerendanbaifenbi),
                                selected: false,
                                itemStyle: {
                                    normal: {
                                        borderRadius: 8,
                                        color: {
                                            type: 'linear',
                                            colorStops: [
                                                { offset: 0, color: '#3A88FC' },
                                                { offset: 1, color: '#0BDCFF' },
                                            ],
                                        },
                                    }
                                },
                                label: {
                                    borderRadius: 20,
                                }
                            },
                            {
                                value: 100 - Number(this.lingyuquerendanbaifenbi),  //数据 按比例显示的
                                name: "",
                                itemStyle: {
                                    color: "transparent",
                                },
                            },
                        ]
                    }
                ]
            });
            var fuzhu = this.$echarts.init(document.getElementById('fu-zhu-zhang'));
            // 绘制图表
            fuzhu.setOption({
                series: [//系列
                    {
                        name: '',
                        type: 'pie',//pie类型的图实现环形图
                        radius: ['52%', '60%'],//数组的话，表示内圆和外圆的半径大小，相对于宽高中较小的那一个。
                        center: ['50%', '50%'],//圆心坐标
                        avoidLabelOverlap: false,//是否启用防止标签重叠策略
                        label: {//每个数据的标签
                            show: false,//设置为true则显示第一个数据
                            position: 'center',//位置居中
                        },
                        color: ['#FBDB00', '#004880'],//系列的颜色
                        emphasis: {//高亮，即鼠标经过时的样式
                            scale: false//表示不放大item
                        },
                        labelLine: {
                            show: true
                        },
                        data: [
                            {
                                value: Number(this.rdbaobaifenbi),
                                selected: false,
                                itemStyle: {
                                    normal: {
                                        borderRadius: 8,
                                        color: {
                                            type: 'linear',
                                            colorStops: [
                                                { offset: 0, color: '#06E4F6' },
                                                { offset: 1, color: '#0AEFDA' },
                                            ],
                                        },
                                    }
                                },
                                label: {
                                    borderRadius: 20,
                                }
                            },
                            {
                                value: 100 - Number(this.rdbaobaifenbi),  //数据 按比例显示的
                                name: "",
                                itemStyle: {
                                    color: "transparent",
                                },
                            },
                        ]
                    }
                ]
            });
        }
    }
}
</script>

<style lang="scss" scoped>
$fontWhiteColor: #FFFFFF;
$fontFamily: 'YouSheBiaoTiHei';

.small-title {
    width: 26.31vw;
    height: 4.35vh;
    background-image: url(../../assets/titleBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 4.35vh;
    text-align: center;
    color: $fontWhiteColor;
    font-size: 1.5rem;
    font-family: $fontFamily;
}

.service-type {
    padding: 1rem 3.5rem 0.5625rem 1.875rem;

    .service-type-item {
        display: flex;
        align-items: flex-start;

        .service-type-item-canvas {
            width: 5.875rem;
            height: 5.875rem;
        }

        .nianshen-canvas {
            background-image: url(../../assets/shenji/nianshen.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .zhuanxiang-canvas {
            background-image: url(../../assets/shenji/zhuanxiang.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .fuzhu-canvas {
            background-image: url(../../assets/shenji/fuzhu.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .service-type-item-line {
            height: 0.125rem;
            width: 17.375rem;
            background-color: #F5F9FF;
            opacity: 0.5;
            position: relative;
        }

        .service-type-item-line::after {
            content: "";
            height: 0.125rem;
            width: 1.2rem;
            background-color: #F5F9FF;
            transform: rotate(-45deg);
            position: absolute;
            top: 0.4rem;
            left: -0.99rem;
        }

        .service-type-item-title {
            margin-left: 1.8125rem;
            margin-top: 1rem;
            color: #BADDF1;
            font-size: 1rem;
        }

        .service-type-item-number {
            margin-left: 1.8125rem;
            margin-top: 0.2rem;
            display: flex;
            align-items: center;
            font-size: 1.25rem;
            font-family: 'RobotoMedium'; 
            color: $fontWhiteColor;
            .number-bai {
                color: #22CD92;
            }
            .number-all {
                margin-left: 1.5625rem;
            }
        }
    }

    .marginTop {
        margin-top: 1.1875rem;
    }
}

.audit-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 26.31vw;

    .audit-info-item {
        width: 11.25rem;
        height: 8.0625rem;
        background-image: url(../../assets/shenji/yishen.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;

        .audit-info-item-number {
            padding-top: 0.75rem;
            font-weight: 500;
            font-size: 1.25rem;
            color: #03E5F1;
            font-family: 'RobotoMedium'; 
        }

        .audit-info-item-text {
            margin-top: 1rem;
            font-weight: 500;
            font-size: 1rem;
            color: #E5F1FB;
        }
    }
}
</style>