import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './assets/main.css'
import './utils/rem'
import './assets/css/font.css'
import './assets/css/fontNumber.css'
import echarts from './utils/echarts'

import axios from 'axios'

axios.defaults.baseURL = 'https://ipaas.jishanyun.com/shujvdaping'
// axios.defaults.baseURL = 'http://192.168.1.118:8999/shujvdaping'
axios.interceptors.request.use( //响应拦截
    config => {
        // config.headers['shujvdapingtoken'] = 'eb76a5be-2c46-4f8d-a241-38380d2af54c'
        return config;
    },
    error => {
        return Promise.error(error);
    }
)

axios.interceptors.response.use((response) => {
    if (response.status == 200) {
        return response.data
    }
})

Vue.prototype.$axios = axios

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$amongItem = null
// Vue.prototype.$remote = 37

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

new Vue({
    router,
    render: function (h) { return h(App) }
}).$mount('#app')
